@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

section.about {
  .link {
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    & {
      text-align: center;
    }
    .title {
      .fontFilicudi();
      font-size: 36px;
      letter-spacing: -2px;
    }
    .sub-title {
      padding: 120px 0 0;
    }
    .meaning {
      padding: 12px 0 0;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 1.5px;
    }
    .description {
      padding: 48px 0 0;
      text-align: center;

      p {
        width: 490px;
        margin: 0 auto;
        text-align: justify;
        text-justify: inter-ideograph;
        word-wrap: break-word;
        word-break: break-all;
        overflow-wrap: break-word;
        letter-spacing: 1.2px;
        line-height: 1.9;
      }
    }
    .link{
      max-width: 1024px;
      margin-top: 80px;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
      padding-right: 30px;
      box-sizing: border-box;
    }
  });

  .mq_sp({
    & {
      text-align: center;
      padding: .vwSp(200)[@r] 0 0;
    }
    .title {
      .fontFilicudi();
      font-size: .vwSp(48)[@r];
      letter-spacing: .vwSp(-2)[@r];
    }
    .sub-title {
      padding: .vwSp(100)[@r] 0 0;

      img {
        width: .vwSp(244)[@r];
      }
    }
    .meaning {
      width: .vwSp(500)[@r];
      margin: 0 auto;
      padding: .vwSp(24)[@r] 0 0;
      font-size: .vwSp(22)[@r];
      font-weight: 900;
      letter-spacing: 1.5px;
    }
    .description {
      padding: .vwSp(100)[@r] 0 0;
      text-align: center;

      p {
        width: .vwSp(542)[@r];
        font-size: .vwSp(24)[@r];
        margin: 0 auto;
        text-align: justify;
        text-justify: inter-ideograph;
        word-wrap: break-word;
        word-break: break-all;
        overflow-wrap: break-word;
        letter-spacing: .vwSp(1.8)[@r];
        line-height: 2.3;
      }
    }
    .link {
      margin-top: .vwSp(80)[@r];
      text-align: center;
    }
  });
}
