@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.contents {
  ul {
    li {
      a {
        display: block;
        position: relative;
      }
      figure {
        .edge-effect();
        padding-top: @thumbnail-aspect-ratio;
        position: relative;
        img {
          .thumbnail-img();
        }
        video {
          opacity: 0;
          .thumbnail-img();
        }
      }
      .text {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        color: @color-white-pure;
        #works-thumbnail-text.common();
        #works-thumbnail-text.pc(45);
        #works-thumbnail-text.sp(40);
      }
    }
  }
  .more {
    text-align: center;
    span {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    ul{
      max-width: @pc-contents-width1088;
      padding-left: 70px;
      padding-right: 70px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      margin-top: 120px;
      li{
        width: calc(50% - 20px);
        &:not(:nth-of-type(-n+2)){
          margin-top: 40px;
        }
        &:nth-of-type(odd){
          margin-right: 40px;
        }
        .text {
          opacity: 0;
          transition-duration: 0.2s;
          transform: translateY(-3px);
          pointer-events: none;
        }
      }
      figure{
        #scale-thumbnail.settings();
        &:before{
          .dark-cover();
          opacity: 0;
          transition-duration: 0.4s;
        }
      }
      li:hover{
        figure{
          #scale-thumbnail.motion();
          &:before {
            opacity: 1;
          }
        }
        .text{
          transition-delay: 0.2s;
          opacity: 1;
          transform: none;
        }
      }
    }
    .more{
      margin-top: 80px;
    }
  });
  .mq_tab({});
  .mq_sp({
    ul{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      margin-top: .vwSp(140)[@r];
      li{
        &:not(:first-of-type){
          margin-top: .vwSp(80)[@r];
        }
        .text{
          opacity: 0;
          transition-duration: 0.4s;
          transform: translateY(-2px);
        }
        figure{
          &:before{
            .dark-cover();
            opacity: 0;
            transition-duration: 0.4s;
          }
        }
      }
      li.show-info{
        figure:before {
          opacity: 1;
        }
        .text{
          transition-delay: 0.2s;
          opacity: 1;
          transform: none;
        }
      }
    }
    .more{
      margin-top: .vwSp(120)[@r];
    }
  });
}
