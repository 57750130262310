@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.news {
  // min-height: 50vh;
  .title {
    .section-title();
  }
  .link {
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    .title{
      margin-bottom: 200px;
    }
    .wrap{
      margin-bottom: 80px;
      a{
        display: block;
        &:not(:last-of-type){
          margin-bottom: 40px;
        }
      }
      dl{
        display: flex;
        dt{
          width: 170px;
          .fontFilicudi();
          flex-shrink: 0;
          span{
            font-size: 12px;
            letter-spacing: -0.02em;
            &:first-of-type:after{
              content: "|";
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
        dd{
          font-size: 14px;
          letter-spacing: 0.1em;
          line-height: 1.7;
          transition-duration: 0.3s;
        }
      }
      a:hover dd{
        opacity: 0.7;
      }
    }
    .link{
      text-align: right;
    }
  });
  .mq_sp({
    padding-left: .vwSp(100)[@r];
    padding-right: .vwSp(100)[@r];
    .title{
      margin-bottom: .vwSp(140)[@r];
    }
    .wrap{
      margin-bottom: .vwSp(80)[@r];
      a{
        display: block;
        &:not(:last-of-type){
          margin-bottom: .vwSp(80)[@r];
        }
      }
      dl{
        dt{
          .fontFilicudi();
          margin-bottom: .vwSp(10)[@r];
          span{
            font-size: 10px;
            letter-spacing: -0.02em;
            &:first-of-type:after{
              content: "|";
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
        dd{
          font-size: .vwSp(24)[@r];
          letter-spacing: 0.1em;
          line-height: 2;
        }
      }
    }
    .link{
      text-align: center;
    }
  });
}
