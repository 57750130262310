@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.intro {
  .title {
    letter-spacing: -0.02em;
    h2 {
      text-align: center;
      font-weight: normal;
    }
    ul {
      display: flex;
      justify-content: center;
      .fontFilicudi();
      font-weight: bold;
      li {
        &:after {
          content: "|";
        }
        &:last-of-type:after {
          display: none;
        }
      }
    }
  }
  .description {
    p {
      line-height: 2;
      letter-spacing: 0.1em;
    }
  }
  .link {
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    &{
      width: 100%;
      max-width: @pc-contents-width1088;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 200px;
    }
    .title{
      margin-bottom: 120px;
      h2{
        font-size: 28px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
      }
      ul{
        font-size: 16px;
        li{
          &:after{
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
    .kv{
      margin-bottom: 120px;
    }
    .description{
      margin-bottom: 80px;
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;
      p{
        font-size: 14px;
      }
    }
  });

  .mq_tab({});
  .mq_sp({
    &{
      margin-bottom: .vwSp(280)[@r];
    }
    .title{
      margin-bottom: .vwSp(140)[@r];
      h2{
        width: .vwSp(550)[@r];
        margin-left: auto;
        margin-right: auto;
        font-size: .vwSp(34)[@r];
        line-height: 1.4;
        margin-bottom: .vwSp(20)[@r];
      }
      ul{
        font-size: .vwSp(22)[@r];
        li{
          &:after{
            padding-left: .vwSp(20)[@r];
            padding-right: .vwSp(20)[@r];
          }
        }
      }
    }

    .kv{
      margin-bottom: .vwSp(140)[@r];
    }
    .description{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      p{
        font-size: .vwSp(24)[@r];
      }
    }

    .link{
      text-align: center;
      a{
        display: inline-block;
        font-size: 18px;
        letter-spacing: -0.02em;
        .fontFilicudi();
        font-weight: bold;
        cursor: pointer;
        position: relative;
        &:after{
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: @color-black-pure;
        }
      }
    }
  });
}
