@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

ul.breadcrumbs {
  .fontFilicudi();
  .mq_pc({
    max-width: 1368px;
    padding-left: 50px;
    margin: 140px auto 40px;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    letter-spacing: -0.02em;
    li:not(:last-of-type){
      margin-right: 10px;
      a{
        &:hover{
          opacity: 0.6;
        }
      }
      &:after{
        content: ">";
        font-weight: bold;
        margin-left: 10px;
      }
    }
    li:last-of-type{
      position: relative;
      &:after{
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: @color-black-pure;
      }
    }
  });
  .mq_tab({});
  .mq_sp({
    padding-left: .vwSp(40)[@r];
    margin-top: .vwSp(310)[@r];
    margin-bottom: .vwSp(90)[@r];
    display: flex;
    flex-direction: row;
    font-size: .vwSp(20)[@r];
    letter-spacing: -0.02em;
    li:not(:last-of-type){
      margin-right: .vwSp(20)[@r];
      a{
        &:hover{
          opacity: 0.6;
        }
      }
      &:after{
        content: ">";
        font-weight: bold;
        margin-left: 10px;
      }
    }
    li:last-of-type{
      position: relative;
      &:after{
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: @color-black-pure;
      }
    }
  });
}
