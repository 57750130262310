@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

/*
FVのトランジション時に表示されるノイズ領域
 */

.overlay {
  & {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
  }

  & > span {
    position: relative;
    z-index: 1;
    background-image: url("@{path-img}common/noise.png");
    background-size: 200px 200px;
    background-position: 10px 10px;
    animation: noise 0.8s steps(10) infinite;
    @keyframes noise {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 100% 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -2px;
      width: 2px;
      height: 100%;
      background-color: #f1f1f1;
    }
  }

  .mq_pc({
    & > span {
      width: 13.5%;
      height: 100%;
      background-color: #f1f1f1;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
    }
  });

  .mq_sp({
    & > span {
      width: 25%;
      height: 100%;
      background-color: #f1f1f1;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
    }
  });
}
