@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

body.top .to-top {
  .mq_pc({
    margin-bottom: 120px;
  });
  .mq_sp({
    margin-bottom: .vwSp(120)[@r];
  });
}

.to-top {
  .fontFilicudi();
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .indicator {
    display: inline-block;
    width: 2px;
    height: 30px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @color-black-pure;
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      height: 4px;
      background-color: @color-white-pure;
      animation: toTopAnimation 2s @ease-circ-inOut infinite;
    }
  }
  .text {
    letter-spacing: -0.02em;
  }
  .mq_pc({
    margin-top: 120px;
    width: 80px;
    cursor: pointer;
    .indicator{
      margin-bottom: 30px;
    }
    .text{
      font-size: 18px;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: fadeout(@color-black-pure, 20%);
        transform: scaleX(0);
        transition-property: transform;
        transition-duration: 0.44s;
        transition-timing-function: @ease-expo-out;
      }
    }
    &:hover .text:after{
      transform: scaleX(1);
    }
  });
  .mq_tab({});
  .mq_sp({
    margin-top: .vwSp(280)[@r];
    .indicator{
      margin-bottom: .vwSp(50)[@r];
    }
    .text{
      font-size: .vwSp(32)[@r];
    }
  });
}
