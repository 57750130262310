@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.related {
  h3 {
    letter-spacing: -0.02em;
    text-align: center;
    .fontFilicudi();
  }
  ul {
    li {
      a {
        display: block;
      }
      figure {
        .edge-effect();
        padding-top: @thumbnail-aspect-ratio;
        position: relative;
        margin-bottom: 24px;
        img {
          .thumbnail-img();
        }
      }
      p {
        letter-spacing: -0.02em;
        .fontFilicudi();
      }
    }
  }
  .more {
    text-align: center;
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    h3{
      font-size: 30px;
      margin-bottom: 80px; 
    }
    ul{
      max-width: @pc-contents-width948;
      padding-left: 70px;
      padding-right: 70px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      li{
        width: 50%;
        &:nth-of-type(1){
          margin-right: 40px;
        }
        figure{
          margin-bottom: 24px;
        }
        p.title{
          font-size: 14px;
          letter-spacing: 0.1em;
          margin-bottom: 14px;
        }
        .info{
          font-size: 12px;
          letter-spacing: 0.02em;
          font-weight: bold;
          .fontFilicudi();
          .partition{
            margin-left: 10px;
            margin-right: 10px;
          }
        }
        figure{
          #scale-thumbnail.settings();
        }
      }
      li:hover{
        figure{
          #scale-thumbnail.motion();
        }
      }
    }
    .more{
      margin-top: 80px;
    }
  });
  .mq_tab({});
  .mq_sp({
    h3{
      font-size: .vwSp(40)[@r];
      margin-bottom: .vwSp(80)[@r];
    }
    ul{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      li{
        &:nth-of-type(1){
          margin-bottom: .vwSp(80)[@r];
        }
        figure{
          margin-bottom: .vwSp(24)[@r];
        }
        p{
          font-size: .vwSp(24)[@r];
          margin-bottom: .vwSp(20)[@r];
        }
        .info{
          font-size: .vwSp(18)[@r];
          letter-spacing: 0.02em;
          font-weight: bold;
          .fontFilicudi();
          .partition{
            margin-left: .vwSp(15)[@r];
            margin-right: .vwSp(15)[@r];
          }
        }
      }
    }
    .more{
      margin-top: .vwSp(100)[@r];
    }
  });
}
