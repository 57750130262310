@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

.l-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
}
