@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.related {
  h3 {
    letter-spacing: -0.02em;
    text-align: center;
    .fontFilicudi();
  }
  ul {
    li {
      a {
        display: block;
      }
      figure {
        .edge-effect();
        padding-top: @thumbnail-aspect-ratio;
        position: relative;
        img {
          .thumbnail-img();
        }
        video {
          opacity: 0;
          .thumbnail-img();
        }
      }
      .text {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        color: @color-white-pure;
        #works-thumbnail-text.common();
        #works-thumbnail-text.pc(36);
        #works-thumbnail-text.sp(40);
      }
    }
  }
  .more {
    text-align: center;
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    h3{
      font-size: 30px;
      margin-bottom: 80px; 
    }
    ul{
      max-width: @pc-contents-width948;
      padding-left: 70px;
      padding-right: 70px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      li{
        width: 50%;
        &:nth-of-type(1){
          margin-right: 40px;
        }
        .text{
          opacity: 0;
          transition-duration: 0.2s;
          transform: translateY(-3px);
          pointer-events: none;
        }
        figure{
          #scale-thumbnail.settings();
          &:before{
            .dark-cover();
            opacity: 0;
            transition-duration: 0.4s;
          }
        }
      }
      li:hover {
        figure {
          #scale-thumbnail.motion();
          &:before {
            opacity: 1;
          }
        }
        .text {
          transition-delay: 0.2s;
          opacity: 1;
          transform: none;
        }
      }
    }
    .more{
      margin-top: 80px;
    }
  });
  .mq_tab({});
  .mq_sp({
    h3{
      font-size: .vwSp(40)[@r];
      margin-bottom: .vwSp(80)[@r];
    }
    ul{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      li{
        &:nth-of-type(1){
          margin-bottom: .vwSp(80)[@r];
        }
        .text{
          opacity: 0;
          transition-duration: 0.4s;
          transform: translateY(-2px);
        }
        figure{
          &:before{
            .dark-cover();
            opacity: 0;
            transition-duration: 0.4s;
          }
        }
      }
      li.show-info{
        figure:before {
          opacity: 1;
        }
        .text{
          transition-delay: 0.2s;
          opacity: 1;
          transform: none;
        }
      }
    }
    .more{
      margin-top: .vwSp(100)[@r];
    }
  });
}
