@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.works {
  min-height: 50vh;
  overflow: hidden;

  * {
    user-select: none;
  }

  .title {
    .section-title();
  }

  .carousel-container {
    position: relative;
    * {
      user-select: none;
    }

    .main {
      position: absolute;
      width: 100%;
      height: 100%;
      //overflow: hidden;

      li {
        position: absolute;
        top: 0;
        left: 0;

        //&:before {
        //  content: "";
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  width: 100%;
        //  height: 100%;
        //  user-select: none;
        //}
      }

      a {
        display: block;
        pointer-events: none;
      }

      figure {
        position: relative;
        z-index: 1;
        mask-image: url("@{path-img}common/edge.png");
        mask-size: cover;

        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
        video {
          opacity: 0;
        }
      }

      figure + span {
        position: relative;
        z-index: 2;
        display: block;
        .fontFilicudi();
        line-height: 1;
        color: #cfd0cf;
      }
    }

    .extra {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      .position {
        .fontFilicudi();
        display: flex;
        align-items: center;
      }

      .btns {
        display: flex;

        span {
          display: block;
          position: relative;
          border: 2px solid black;
          cursor: pointer;
          border-radius: 100%;
          background-size: cover;
          &:before,
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
          }
          &:before {
          }
          &:after {
          }
        }

        .next {
          transform: scaleX(-1);
        }

        .prev {
        }
      }
    }
  }

  .all {
    text-align: right;
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }

  .mq_pc({
    &{
      margin-bottom: 400px;
    }
    .carousel-container {
      margin-top: 200px;
      height: .vwPc(650)[@r];
      .main {
        li {
          width: .vwPc(560) [@r];
          cursor: grab;

          a {
            will-change: transform;
            transition-property: transform;
            transition-timing-function: @ease-quart-out;
            transition-duration: 500ms;
          }

          &:hover {
            a {
              transform: scale(1.08);
            }
            figure{

            }
          }
        }

        figure {
          height: .vwPc(400) [@r];
        }

        figure + span {
          margin-top: .vwPc(-25)[@r];
          font-size: .vwPc(48)[@r];
        }
      }
      .extra {
          top: .vwPc(520) [@r];
          right: 150px;
        .position{
          font-size: 26px;
          height: 80px;
          margin-right: 80px;
          .spacer{
            height: 2px;
            width: 60px;
            background-color: @color-black-pure;
            margin: 0 20px;
          }
        }
        .btns {
          gap:40px;

          span {
            width: 80px;
            height: 80px;
            cursor: pointer;
            &:before{
              background-color: @color-black-pure;
              opacity: 0;
              border-radius: 100%;
              transform: scale(0);
              transition-property: opacity, transform;
              transition-timing-function: @ease-quart-out;
              transition-duration: 200ms;
            }
            &:hover:before{
              opacity: 1;
              transform: scale(1.01);
            }
            &:after{
              background-image: url("@{path-img}carousel/arrow_pc_black.png"),
                url("@{path-img}carousel/arrow_pc_white.png");
                background-size: cover, 0;
            }
            &:hover:after{
              background-size: 0, cover;
              // background-image: url( @{path-img}carousel/arrow_pc_white.png );
            }
          }
        }
      }
    }
    .all{
      padding-right: 150px;
    }
  });
  .mq_sp({
    &{
      margin-bottom: .vwSp(280)[@r];
    }
    .carousel-container {
      margin-top: .vwSp(150)[@r];
      height: .vwSp(750)[@r];


      .main {
        li {
          width: .vwSp(560) [@r];
        }

        figure {
          height: .vwSp(400) [@r];
        }
        figure + span{
          font-size: .vwSp(56)[@r];
          margin-top: .vwSp(-25)[@r];
        }
      }

      .extra {
        &{
          top: .vwSp(540)[@r];
          right: .vwSp(40)[@r];
        }
        .position{
          font-size: .vwSp(34)[@r];
          margin-right: .vwSp(166)[@r];
          .spacer{
            height: 2px;
            width: .vwSp(80)[@r];
            background-color: @color-black-pure;
            margin: 0 .vwSp(20)[@r];
          }
        }
        .btns {
          gap: .vwSp(40)[@r];

          span {
            width: .vwSp(100)[@r];
            height: .vwSp(100)[@r];
            &:after{
              background-image: url("@{path-img}carousel/arrow_sp.png");
            }
          }
        }
      }
    }
    .all{
      text-align: center;
    }
  });
}
