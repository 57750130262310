@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

.u-all {
  position: absolute;
  opacity: 0;
}

.mq_pc({
  .u-sp {
    display: none !important;
  }
});

.mq_sp({
  .u-pc {
    display: none !important;
  }
});
