@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

section.how {
  .lottie {
    margin: 0 auto;
  }
  .link {
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }

  .mq_pc({
    & {
      padding: 350px 0 0;
      margin-bottom: 400px;
    }
    .title {
      font-size: 36px;
      .fontFilicudi();
      letter-spacing: -2px;
      text-align: center;
    }
    .img {
      padding: 200px 0 0;
      text-align: center;

      img {
        transform: translateX(34px);
      }

      .lottie {
        width: 1024px;
        transform: translateX(34px);
      }
    }
    .link{
      max-width: 1024px;
      margin-top: 80px;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
      padding-right: 30px;
      box-sizing: border-box;
    }
  });
  @media all and (-ms-high-contrast: none) {
    .img {
      svg {
        height: 377px !important;
      }
    }
  }

  @media screen and (max-width: 1120px) and (min-width: 950px) {
    .img {
      img,
      .lottie {
        width: 90%;
        transform: translateX(3%);
      }
    }
  }

  .mq_tab({
    .img {
      img, .lottie {
        width: 85%;
        transform: translateX(3%);
      }
    }
  });

  .mq_sp({
    & {
      padding: .vwSp(280)[@r] 0 0;
      margin-bottom: .vwSp(280)[@r];
    }
    .title {
      font-size: .vwSp(48)[@r];
      .fontFilicudi();
      letter-spacing: .vwSp(-2)[@r];
      text-align: center;
      transform: scaleX(0.98);
    }
    .img {
      padding: .vwSp(140)[@r] 0 0;
      text-align: center;

      img, .lottie {
        width: .vwSp(399)[@r];
      }
    }
    .link {
      margin-top: .vwSp(80)[@r];
      text-align: center;
    }
  });
}
