@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

footer.global-footer {
  .mq_pc({
    & {
      border-top: 1px solid #e0e4e7;
      padding: 52px 0 46px;
    }
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1368px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto;
    }
    .icons{
      #sns-icons.common();
      a{
        height: 21px;
        transition-duration: 0.2s;
        &:not(:last-of-type){
          margin-right: 20px;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      img.hytek {
        width: 103px;
        height: 30px;
      }

      p.copyright {
        color: @color-gray;
        padding: 12px 0 0;
        font-size: 12px;
        .fontFilicudi();
        line-height: 1.2;
        letter-spacing: -0.6px;
        text-align: center;
      }
    }
    .extra{
      flex-shrink: 0;
      font-size: 13px;
      .fontFilicudi();
      letter-spacing: -0.02em;
      a{
        color: @color-gray;
        transition-duration: 0.2s;
        &:not(:last-of-type){
          margin-right: 40px;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    }
  });

  .mq_sp({
    & {
      border-top: 1px solid #e0e4e7;
      padding: .vwSp(100)[@r] 0;
    }
    .inner {
      width: .vwSp(624)[@r];
      margin: 0 auto;
    }
    .main {
      text-align: center;
      img {
        width: .vwSp(182)[@r];
        height: .vwSp(57)[@r];
      }
      p.copyright {
        padding: .vwSp(26)[@r] 0 0;
        font-size: .vwSp(24)[@r];
        .fontFilicudi();
        line-height: 1.2;
        letter-spacing: -0.6px;
      }
    }
  });
}
