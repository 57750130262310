@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

& {
  h2 {
    .section-title();
  }
  .inner-nav {
    #inner-nav.common();
    #inner-nav.pc(24px);
    #inner-nav.sp(32);
  }
  form {
    input[type="submit"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
      #rect-btn.common();
      #rect-btn.pc(120px, 48px, 18px);
      #rect-btn.sp(200, 72, 30);
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      outline: none;
      background: transparent;
      #rect-btn.common();
      #rect-btn.pc(240px, 48px, 18px);
      #rect-btn.sp(320, 72, 30);
      background-repeat: no-repeat;
    }
  }
  .mq_pc({
    h2{
      margin-bottom: 120px;
    }
    form{
      display: flex;
      justify-content: flex-end;
      max-width: @pc-contents-width1088;
      padding-left: 70px;
      padding-right: 70px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      select{
        padding-left: 100px;
        margin-right: 24px;
        background-position: right 20px top 15px;
        background-image: url("@{path-img}icon/arw_bottom_bk.svg"),
          url("@{path-img}icon/arw_bottom_wh.svg");
        background-size: 12px 12px, 0;
        &:hover{
          background-size: 0, 12px 12px;
        }
      }
    }
});

  .mq_tab({});
  .mq_sp({
    h2{
      margin-bottom: .vwSp(140)[@r];
    }
    .inner-nav{
      margin-bottom: .vwSp(40)[@r];
      ul{
        justify-content: start;
        overflow-x: scroll;
        padding-left: .vwSp(100)[@r];
        padding-right: .vwSp(100)[@r];
        padding-bottom: .vwSp(15)[@r];
        li{
          flex-shrink: 0;
        }
      }
    }
    form{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      display: flex;
      justify-content: space-between;
      select{
        padding-left: .vwSp(130)[@r];
        background-image: url("@{path-img}icon/arw_bottom_bk.svg");
        background-size: .vwSp(28)[@r] .vwSp(17)[@r];
        background-position: right .vwSp(25)[@r] top .vwSp(20)[@r];
      }
    }
  });
}
