@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.mission {
  .title {
    .h3();
  }
  .mq_pc({
    & {
      padding: 110px 0 0;
      margin-bottom: 400px - 100px;
    }
    .text{
      padding: 80px 0 0;
      width: 480px;
      margin-left: auto;
      margin-right: auto;
      p{
        font-size: 14px;
        letter-spacing: 0.1em;
        line-height: 2;
        word-break: break-all;
        text-align: justify;
        &.lead{
          margin-top: 50px;
          font-weight: bold;
          text-align: center;
        }
        &:not(:last-of-type){
          margin-bottom: 20px;
        }
      }
    }
  });

  .mq_tab({});

  .mq_sp({
    & {
      padding: .vwSp(140)[@r] .vwSp(100)[@r] 0;
      margin-bottom: .vwSp(280 - 110)[@r];
    }
    .text{
      padding: .vwSp(80)[@r] 0 0;
      p{
        font-size: .vwSp(24)[@r];
        letter-spacing: 0.1em;
        line-height: 2;
        word-break: break-all;
        text-align: justify;
        &.lead{
          margin-top: .vwSp(60)[@r];
          font-weight: bold;
          text-align: center;
        }
        &:not(:last-of-type){
          margin-bottom: .vwSp(20)[@r];
        }
      }
    }
  });
}
