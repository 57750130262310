@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

section.contact {
  .text {
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      user-select: none;
      // display: none;
    }
  }

  .mq_pc({
    & {
      padding: 389px 0 226px;
    }

    .title {
      font-size: 36px;
      .fontFilicudi();
      letter-spacing: -2px;
      text-align: center;
    }

    .img {
      width: 384px;
      margin: 160px auto 0;
      cursor: pointer;
    }

    .text {
      padding: 81px 0 0;
      text-align: center;

      span {
        position: relative;
        font-size: 18px;
        .fontFilicudi();
        display: inline-block;
        letter-spacing: -0.5px;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: black;
        }
      }
    }
  });

  .mq_sp({
    & {
      padding: .vwSp(280)[@r] 0 .vwSp(324)[@r];
    }

    .title {
      font-size: .vwSp(48)[@r];
      .fontFilicudi();
      letter-spacing: .vwSp(-2)[@r];
      text-align: center;
    }

    .img {
      width: .vwSp(499)[@r];
      margin: .vwSp(110)[@r] auto 0;
    }

    .text {
      padding: .vwSp(80)[@r] 0 0;
      text-align: center;

      span {
        position: relative;
        font-size: .vwSp(30)[@r];
        .fontFilicudi();
        display: inline-block;
        letter-spacing: -0.5px;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: black;
        }
      }
    }
  });
}
