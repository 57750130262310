@import (inline) "./_include/sanitize.css";

@import "./_include/reset";
@import "./main/font";
@import "./main/base";
@import "./main/keyframes";
@import "./main/page";
@import "./main/cursor";
@import "./main/display";
@import "./main/overlay";
@import "./main/loading";
@import "./main/header";
@import "./main/footer";
@import "./main/to-top";
@import "./main/breadcrumbs";

body.top {
  @import "top/fv";
  @import "top/about";
  @import "top/how";
  @import "top/works";
  @import "top/news";
  @import "top/contact";
}

body.about {
  @import "about/head";
  @import "about/mission";
  @import "about/founder";
  @import "about/company";
  @import "about/contact";
}

body.works {
  @import "works_top/head";
  @import "works_top/contents";
}

body.works-sub {
  @import "works_sub/intro";
  @import "works_sub/main";
  @import "works_sub/related";
}

body.news {
  @import "news_top/head";
  @import "news_top/contents";
}

body.news-sub {
  @import "news_sub/intro";
  @import "news_sub/main";
  @import "news_sub/related";
}

body.privacypolicy {
  @import "privacypolicy/index";
}

body.terms {
  @import "terms/index";
}

body.maintenance {
  header {
    padding: 20px;
    text-align: center;
    img {
      width: 150px;
    }
  }
  main {
    padding: 50px;
    text-align: center;
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
      margin-top: 50px;
    }
  }
}
