/* Document
 * ========================================================================== */

/**
 * Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * Collapse border spacing in all browsers (opinionated).
 */

table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */

fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

figure {
  margin: 0;
}
@font-face {
  font-family: "Kinto Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/KintoSans-Medium.woff2") format("woff2"), url("../fonts/KintoSans-Medium.woff") format("woff"), url("../fonts/KintoSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Kinto Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/KintoSans-Black.woff2") format("woff2"), url("../fonts/KintoSans-Black.woff") format("woff"), url("../fonts/KintoSans-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/NotoSansJP-Medium.woff2") format("woff2"), url("../fonts/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NotoSansJP-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/NotoSansJP-Black.woff2") format("woff2"), url("../fonts/NotoSansJP-Black.woff") format("woff"), url("../fonts/NotoSansJP-Black.ttf") format("truetype");
}
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/YakuHanJP-DemiLight.eot");
  src: url("../fonts/YakuHanJP-DemiLight.woff2") format("woff2"), url("../fonts/YakuHanJP-DemiLight.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01, U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/YakuHanJP-Regular.eot");
  src: url("../fonts/YakuHanJP-Regular.woff2") format("woff2"), url("../fonts/YakuHanJP-Regular.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01, U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/YakuHanJP-Medium.eot");
  src: url("../fonts/YakuHanJP-Medium.woff2") format("woff2"), url("../fonts/YakuHanJP-Medium.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01, U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/YakuHanJP-Bold.eot");
  src: url("../fonts/YakuHanJP-Bold.woff2") format("woff2"), url("../fonts/YakuHanJP-Bold.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01, U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
a,
abbr,
address,
blockquote,
body,
caption,
cite,
code,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
label,
legend,
li,
object,
ol,
p,
pre,
q,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
ul {
  border: 0;
  margin: 0;
  padding: 0;
}
html {
  font-family: "YakuHanJP", "Noto Sans JP", "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "メイリオ", Meiryo, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  min-height: 100%;
  height: 100%;
  overflow: overlay;
}
body {
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  overflow: overlay;
  min-height: 100%;
  height: 100%;
}
@keyframes noise {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
a {
  text-decoration: none;
  color: black;
}
img {
  max-width: 100%;
  height: auto;
}
ul,
li {
  list-style: none;
}
dl,
dt,
dd {
  margin: 0;
}
@keyframes underlineAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  20% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes toTopAnimation {
  0% {
    transform: translateY(34px);
  }
  100% {
    transform: translateY(-4px);
  }
}
.noise-bg {
  position: fixed;
  width: 100%;
  height: 100%;
}
.noise-bg canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.scroll-wrapper {
  position: relative;
  z-index: 100;
}
.contents-wrapper {
  min-height: 100vh;
}
@media only screen and (min-width: 750px) {
  .scroll-wrapper {
    padding-top: 240px;
  }
}
@media only screen and (max-width: 749px) {
  .scroll-wrapper {
    padding-top: 32vw;
  }
}
.spinner {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
}
.spinner .sk-circle {
  width: 40px;
  height: 40px;
  position: relative;
}
.spinner .sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.spinner .sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.spinner .sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.spinner .sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.spinner .sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.spinner .sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.spinner .sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.spinner .sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.spinner .sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.spinner .sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.spinner .sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.spinner .sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.spinner .sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.spinner .sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.spinner .sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.spinner .sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.spinner .sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.spinner .sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.spinner .sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.spinner .sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.spinner .sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.temp-wrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  visibility: hidden;
  pointer-events: none;
}
.cursor-wrapper * {
  user-select: none;
}
@media only screen and (min-width: 750px) {
  .cursor-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
  }
  .cursor-wrapper .cursor {
    position: relative;
  }
  .cursor-wrapper .pointer {
    width: 140px;
    height: 140px;
    background-color: black;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);
    position: relative;
    left: -65px;
    top: -65px;
  }
  .cursor-wrapper .arrow {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25%, -50%);
    will-change: opacity;
    opacity: 0;
  }
  .cursor-wrapper .arrow span {
    display: block;
    transform: translateX(-35px) scaleX(0);
    will-change: transform;
  }
  .cursor-wrapper .drag {
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: 0.2em;
    transform: translate(-37%, -85%);
    font-size: 18px;
    opacity: 0;
    will-change: opacity;
  }
  .cursor-wrapper .drag span {
    display: block;
    will-change: transform;
    transform: translateY(20px);
  }
}
@media only screen and (max-width: 749px) {
  .cursor-wrapper {
    display: none;
  }
}
*[data-cursor="coming"] {
  cursor: default !important;
}
.u-all {
  position: absolute;
  opacity: 0;
}
@media only screen and (min-width: 750px) {
  .u-sp {
    display: none !important;
  }
}
@media only screen and (max-width: 749px) {
  .u-pc {
    display: none !important;
  }
}
/*
FVのトランジション時に表示されるノイズ領域
 */
.overlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}
.overlay > span {
  position: relative;
  z-index: 1;
  background-image: url("../images/common/noise.png");
  background-size: 200px 200px;
  background-position: 10px 10px;
  animation: noise 0.8s steps(10) infinite;
}
@keyframes noise {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
.overlay > span::before {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  width: 2px;
  height: 100%;
  background-color: #f1f1f1;
}
@media only screen and (min-width: 750px) {
  .overlay > span {
    width: 13.5%;
    height: 100%;
    background-color: #f1f1f1;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
  }
}
@media only screen and (max-width: 749px) {
  .overlay > span {
    width: 25%;
    height: 100%;
    background-color: #f1f1f1;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
  }
}
.l-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
}
header.global-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
header.global-header .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e6e6;
}
header.global-header .inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
header.global-header .lang-selector {
  position: absolute;
  top: 0;
  right: 0;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
header.global-header .lang-selector dl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header.global-header .lang-selector ul {
  display: flex;
}
header.global-header .lang-selector .btn {
  display: inline-block;
  position: relative;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}
header.global-header .lang-selector .btn::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: black;
  transition: height 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}
@media only screen and (min-width: 750px) {
  header.global-header {
    height: 65px;
    padding: 0 50px;
  }
  header.global-header nav.main-nav {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    font-size: 14px;
  }
  header.global-header nav.main-nav a {
    margin-right: 40px;
    position: relative;
  }
  header.global-header nav.main-nav a:after {
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background-color: #000000;
    position: absolute;
    bottom: -3px;
    left: 0;
    transform: scaleX(0);
    transform-origin: center center;
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 0.3s, 0.5s;
    transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1), cubic-bezier(0.16, 1, 0.3, 1);
  }
  header.global-header nav.main-nav a:hover:after,
  header.global-header nav.main-nav a.current:after {
    transform: scaleX(1);
    opacity: 1;
  }
  header.global-header .logo {
    width: 110px;
    display: block;
    height: auto;
  }
  header.global-header .lang-selector dt {
    font-size: 12px;
    padding-right: 32px;
    padding-top: 23px;
    padding-bottom: 0.5px;
  }
  header.global-header .lang-selector li {
    padding-left: 20px;
  }
  header.global-header .lang-selector li:first-child {
    padding-left: 0;
  }
  header.global-header .lang-selector .btn {
    font-size: 12px;
    padding-top: 23px;
    cursor: pointer;
  }
  header.global-header .lang-selector .btn::before {
    left: 6px;
    width: 2px;
    height: 0px;
  }
  header.global-header .lang-selector .btn.current {
    opacity: 1;
  }
  header.global-header .lang-selector .btn.current::before {
    height: 20px;
  }
  header.global-header .menu-trigger,
  header.global-header .sp-menu {
    display: none;
  }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {
  header.global-header nav.main-nav a {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 749px) {
  header.global-header .inner {
    height: 13.33333333vw;
  }
  header.global-header .logo {
    width: 24vw;
  }
  header.global-header .menu-trigger {
    display: block;
    height: 100%;
    width: 18.66666667vw;
    position: absolute;
    top: 0;
    right: 0;
  }
  header.global-header .menu-trigger span {
    display: block;
    height: 2px;
    width: 10.66666667vw;
    position: absolute;
    top: 6.53333333vw;
    left: 4vw;
    transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
    transition-property: transform;
    transition-duration: 0.7s;
    transform-origin: center center;
    overflow: hidden;
    will-change: transform;
  }
  header.global-header .menu-trigger span:nth-child(1) {
    transform: translateY(-5px);
  }
  header.global-header .menu-trigger span:nth-child(2) {
    transform: translateY(5px);
  }
  header.global-header .menu-trigger span:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000000;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    transition-property: transform;
    transition-duration: 0.7s;
  }
  header.global-header .menu-trigger span:nth-child(2):after {
    transform: translateX(-20%);
  }
  header.global-header .menu-trigger.close span:nth-child(1) {
    transform: rotate(45deg) scaleX(0.9);
  }
  header.global-header .menu-trigger.close span:nth-child(2) {
    transform: rotate(-45deg) scaleX(0.9);
  }
  header.global-header .menu-trigger.close span:nth-child(2):after {
    transform: none;
  }
  header.global-header nav.sp-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 9.33333333vw;
    letter-spacing: -0.03em;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  header.global-header nav.sp-menu ul {
    position: relative;
  }
  header.global-header nav.sp-menu .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 8.53333333vw;
    gap: 4vw;
  }
  header.global-header nav.sp-menu .extra {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 4.26666667vw;
    gap: 3.33333333vw;
  }
  header.global-header nav.sp-menu .lang {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 4.26666667vw;
    gap: 9.33333333vw;
  }
  header.global-header nav.sp-menu .lang a {
    opacity: 0.5;
    position: relative;
  }
  header.global-header nav.sp-menu .lang a.current {
    opacity: 1;
  }
  header.global-header nav.sp-menu .lang a.current:before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: #000000;
    position: absolute;
    bottom: -4px;
    left: 0;
  }
  header.global-header nav.sp-menu .icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header.global-header nav.sp-menu .icons li:not(:last-of-type) {
    margin-right: 8vw;
  }
  header.global-header .main-nav,
  header.global-header .lang-selector {
    display: none;
  }
}
@media only screen and (min-width: 750px) {
  footer.global-footer {
    border-top: 1px solid #e0e4e7;
    padding: 52px 0 46px;
  }
  footer.global-footer .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1368px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }
  footer.global-footer .icons {
    display: flex;
  }
  footer.global-footer .icons a {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  footer.global-footer .icons a {
    height: 21px;
    transition-duration: 0.2s;
  }
  footer.global-footer .icons a:not(:last-of-type) {
    margin-right: 20px;
  }
  footer.global-footer .icons a:hover {
    opacity: 0.6;
  }
  footer.global-footer .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  footer.global-footer .main img.hytek {
    width: 103px;
    height: 30px;
  }
  footer.global-footer .main p.copyright {
    color: #4C4C4C;
    padding: 12px 0 0;
    font-size: 12px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    line-height: 1.2;
    letter-spacing: -0.6px;
    text-align: center;
  }
  footer.global-footer .extra {
    flex-shrink: 0;
    font-size: 13px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  footer.global-footer .extra a {
    color: #4C4C4C;
    transition-duration: 0.2s;
  }
  footer.global-footer .extra a:not(:last-of-type) {
    margin-right: 40px;
  }
  footer.global-footer .extra a:hover {
    opacity: 0.6;
  }
}
@media only screen and (max-width: 749px) {
  footer.global-footer {
    border-top: 1px solid #e0e4e7;
    padding: 13.33333333vw 0;
  }
  footer.global-footer .inner {
    width: 83.2vw;
    margin: 0 auto;
  }
  footer.global-footer .main {
    text-align: center;
  }
  footer.global-footer .main img {
    width: 24.26666667vw;
    height: 7.6vw;
  }
  footer.global-footer .main p.copyright {
    padding: 3.46666667vw 0 0;
    font-size: 3.2vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    line-height: 1.2;
    letter-spacing: -0.6px;
  }
}
@media only screen and (min-width: 750px) {
  body.top .to-top {
    margin-bottom: 120px;
  }
}
@media only screen and (max-width: 749px) {
  body.top .to-top {
    margin-bottom: 16vw;
  }
}
.to-top {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.to-top .indicator {
  display: inline-block;
  width: 2px;
  height: 30px;
  position: relative;
}
.to-top .indicator:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000000;
}
.to-top .indicator:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  animation: toTopAnimation 2s cubic-bezier(0.85, 0, 0.15, 1) infinite;
}
.to-top .text {
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  .to-top {
    margin-top: 120px;
    width: 80px;
    cursor: pointer;
  }
  .to-top .indicator {
    margin-bottom: 30px;
  }
  .to-top .text {
    font-size: 18px;
  }
  .to-top .text:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.8);
    transform: scaleX(0);
    transition-property: transform;
    transition-duration: 0.44s;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
  .to-top:hover .text:after {
    transform: scaleX(1);
  }
}
@media only screen and (max-width: 749px) {
  .to-top {
    margin-top: 37.33333333vw;
  }
  .to-top .indicator {
    margin-bottom: 6.66666667vw;
  }
  .to-top .text {
    font-size: 4.26666667vw;
  }
}
ul.breadcrumbs {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  ul.breadcrumbs {
    max-width: 1368px;
    padding-left: 50px;
    margin: 140px auto 40px;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    letter-spacing: -0.02em;
  }
  ul.breadcrumbs li:not(:last-of-type) {
    margin-right: 10px;
  }
  ul.breadcrumbs li:not(:last-of-type) a:hover {
    opacity: 0.6;
  }
  ul.breadcrumbs li:not(:last-of-type):after {
    content: ">";
    font-weight: bold;
    margin-left: 10px;
  }
  ul.breadcrumbs li:last-of-type {
    position: relative;
  }
  ul.breadcrumbs li:last-of-type:after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #000000;
  }
}
@media only screen and (max-width: 749px) {
  ul.breadcrumbs {
    padding-left: 5.33333333vw;
    margin-top: 41.33333333vw;
    margin-bottom: 12vw;
    display: flex;
    flex-direction: row;
    font-size: 2.66666667vw;
    letter-spacing: -0.02em;
  }
  ul.breadcrumbs li:not(:last-of-type) {
    margin-right: 2.66666667vw;
  }
  ul.breadcrumbs li:not(:last-of-type) a:hover {
    opacity: 0.6;
  }
  ul.breadcrumbs li:not(:last-of-type):after {
    content: ">";
    font-weight: bold;
    margin-left: 10px;
  }
  ul.breadcrumbs li:last-of-type {
    position: relative;
  }
  ul.breadcrumbs li:last-of-type:after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #000000;
  }
}
body.top section.fv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body.top section.fv .main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body.top section.fv .canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  pointer-events: none;
}
body.top section.fv .canvas-wrapper canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
body.top section.fv .logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
}
body.top section.fv .logo-inner {
  position: relative;
  overflow: hidden;
}
body.top section.fv .logo-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: white;
}
body.top section.fv ul.characters {
  opacity: 0;
}
body.top section.fv .triangle {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 19px;
  transform: translateX(-1.1px);
}
body.top section.fv .scroll-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
}
body.top section.fv .scroll-bar {
  position: absolute;
  top: -16px;
  left: 43%;
  width: 2px;
  height: 46px;
  background-color: black;
  overflow: hidden;
  z-index: 4;
}
body.top section.fv .scroll-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 4px;
  background-color: white;
  animation: bar 1.8s cubic-bezier(0.85, 0, 0.15, 1) infinite;
}
@keyframes bar {
  0% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(46px);
  }
}
body.top section.fv .video-wrapper {
  height: 100%;
  width: 100%;
}
body.top section.fv .inner {
  position: relative;
  height: 100%;
  width: 100%;
}
body.top section.fv ul.characters {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  display: grid;
  grid-template-columns: 1fr;
}
body.top section.fv ul.characters li {
  width: 100%;
  pointer-events: none;
  flex-shrink: 0;
}
body.top section.fv .extra {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  pointer-events: none;
}
body.top section.fv .title {
  position: relative;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  text-align: center;
}
body.top section.fv .p-fv__scroll {
  position: absolute;
  display: flex;
  justify-content: center;
}
body.top section.fv .scroll-inner {
  position: relative;
}
@media all and (-ms-high-contrast: none) {
  body.top section.fv .video-wrapper {
    z-index: 1;
  }
}
@media only screen and (min-width: 750px) {
  body.top section.fv .video-wrapper {
    position: relative;
  }
  body.top section.fv .video-wrapper video {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-width: inherit;
    transform: translate(-50%, -50%);
  }
  body.top section.fv ul.characters {
    grid-template-rows: 1fr 174px 237px 176px 1fr;
  }
  body.top section.fv ul.characters li.RU {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    background-image: url("../images/fv/ru.png");
    background-repeat: repeat;
    background-position: bottom;
  }
  body.top section.fv ul.characters li.KR {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    background-image: url("../images/fv/kr.png");
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 390px 125px;
  }
  body.top section.fv ul.characters li.EN {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    background-image: url("../images/fv/en.png");
    background-repeat: repeat-x;
    background-position: center;
    background-size: 1610px 190px;
    transform: translateX(1.5px) translateY(1px);
  }
  body.top section.fv ul.characters li.CH {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    background-image: url("../images/fv/ch.png");
    background-repeat: repeat-x;
    background-position: top;
  }
  body.top section.fv ul.characters li.TA {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
    background-image: url("../images/fv/ta.png");
    background-repeat: repeat;
    background-position: top;
  }
  body.top section.fv .logo img {
    width: 620px;
  }
  body.top section.fv .extra {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  body.top section.fv .title {
    padding-bottom: 450px;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  body.top section.fv .title img {
    width: 224px;
  }
  body.top section.fv .scroll-indicator {
    bottom: 20%;
    left: calc(50% - 26px);
    padding-top: 450px;
  }
  body.top section.fv .scroll-inner {
    width: 33px;
    height: 26px;
    transform: translate(12px);
  }
}
@media only screen and (min-width: 750px) and screen and (max-height: 620px) {
  body.top section.fv ul.characters {
    display: flex !important;
  }
  body.top section.fv li {
    display: none !important;
  }
  body.top section.fv li.EN {
    display: block !important;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.fv .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  body.top section.fv .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 387.2vw;
    transform: translateX(-32%);
  }
  body.top section.fv .inner {
    position: fixed;
  }
  body.top section.fv ul.characters {
    grid-template-rows: 1fr 20.8vw 28vw 41.73333333vw 28.93333333vw 28.26666667vw 1fr;
  }
  body.top section.fv ul.characters li.IND01 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    background-repeat: repeat;
    background-image: url("../images/fv/ind_sp.png");
    background-size: 86.4vw 28.8vw;
    background-position: bottom;
  }
  body.top section.fv ul.characters li.RU {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    background-repeat: repeat;
    background-position: bottom;
    background-image: url("../images/fv/ru_sp.png");
    background-size: 116.13333333vw 28vw;
  }
  body.top section.fv ul.characters li.KR {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    background-image: url("../images/fv/kr_sp.png");
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 56.93333333vw 18.26666667vw;
  }
  body.top section.fv ul.characters li.EN {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    background-size: 73.06666667vw 18.93333333vw;
    background-image: url("../images/fv/en_sp.png");
    background-repeat: repeat-x;
    background-position: center;
    transform: translateX(0.6vw) translateY(0.8vw);
  }
  body.top section.fv ul.characters li.CH {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
    background-image: url("../images/fv/ch_sp.png");
    background-repeat: repeat-x;
    background-size: 69.86666667vw 19.46666667vw;
  }
  body.top section.fv ul.characters li.TA {
    grid-row: 6 / 7;
    grid-column: 1 / 2;
    background-image: url("../images/fv/ta_sp.png");
    background-repeat: repeat-x;
    background-size: 74.26666667vw 19.86666667vw;
    background-position: top;
  }
  body.top section.fv ul.characters li.IND {
    grid-row: 7 / 8;
    grid-column: 1 / 2;
    background-repeat: repeat;
    background-image: url("../images/fv/ind_sp.png");
    background-size: 86.4vw 28.8vw;
    background-position: top;
  }
  body.top section.fv .logo {
    transform: translateY(-3.6vw) translateX(1px);
  }
  body.top section.fv .logo img {
    width: 84.8vw;
  }
  body.top section.fv .extra {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  body.top section.fv .title {
    padding-top: 32.4vw;
    padding-bottom: 110.66666667vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.top section.fv .title img {
    width: 45.73333333vw;
  }
  body.top section.fv .scroll-indicator {
    bottom: 20%;
    left: calc(50% - 26px);
    padding-top: 450px;
  }
  body.top section.fv .scroll-inner {
    width: 33px;
    height: 26px;
    transform: translate(12px);
  }
}
body.top section.about .link a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.top section.about .link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.top section.about .link a {
    font-size: 18px;
  }
  body.top section.about .link a:after {
    margin-top: 3px;
  }
  body.top section.about .link a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.top section.about .link a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.top section.about {
    text-align: center;
  }
  body.top section.about .title {
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    font-size: 36px;
    letter-spacing: -2px;
  }
  body.top section.about .sub-title {
    padding: 120px 0 0;
  }
  body.top section.about .meaning {
    padding: 12px 0 0;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1.5px;
  }
  body.top section.about .description {
    padding: 48px 0 0;
    text-align: center;
  }
  body.top section.about .description p {
    width: 490px;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-ideograph;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    letter-spacing: 1.2px;
    line-height: 1.9;
  }
  body.top section.about .link {
    max-width: 1024px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    padding-right: 30px;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.about {
    text-align: center;
    padding: 26.66666667vw 0 0;
  }
  body.top section.about .title {
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
  body.top section.about .sub-title {
    padding: 13.33333333vw 0 0;
  }
  body.top section.about .sub-title img {
    width: 32.53333333vw;
  }
  body.top section.about .meaning {
    width: 66.66666667vw;
    margin: 0 auto;
    padding: 3.2vw 0 0;
    font-size: 2.93333333vw;
    font-weight: 900;
    letter-spacing: 1.5px;
  }
  body.top section.about .description {
    padding: 13.33333333vw 0 0;
    text-align: center;
  }
  body.top section.about .description p {
    width: 72.26666667vw;
    font-size: 3.2vw;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-ideograph;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    letter-spacing: 0.24vw;
    line-height: 2.3;
  }
  body.top section.about .link {
    margin-top: 10.66666667vw;
    text-align: center;
  }
}
body.top section.how .lottie {
  margin: 0 auto;
}
body.top section.how .link a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.top section.how .link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.top section.how .link a {
    font-size: 18px;
  }
  body.top section.how .link a:after {
    margin-top: 3px;
  }
  body.top section.how .link a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.top section.how .link a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.top section.how {
    padding: 350px 0 0;
    margin-bottom: 400px;
  }
  body.top section.how .title {
    font-size: 36px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -2px;
    text-align: center;
  }
  body.top section.how .img {
    padding: 200px 0 0;
    text-align: center;
  }
  body.top section.how .img img {
    transform: translateX(34px);
  }
  body.top section.how .img .lottie {
    width: 1024px;
    transform: translateX(34px);
  }
  body.top section.how .link {
    max-width: 1024px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    padding-right: 30px;
    box-sizing: border-box;
  }
}
@media all and (-ms-high-contrast: none) {
  body.top section.how .img svg {
    height: 377px !important;
  }
}
@media screen and (max-width: 1120px) and (min-width: 950px) {
  body.top section.how .img img,
  body.top section.how .img .lottie {
    width: 90%;
    transform: translateX(3%);
  }
}
@media only screen and (min-width: 750px) and (max-width: 950px) {
  body.top section.how .img img,
  body.top section.how .img .lottie {
    width: 85%;
    transform: translateX(3%);
  }
}
@media only screen and (max-width: 749px) {
  body.top section.how {
    padding: 37.33333333vw 0 0;
    margin-bottom: 37.33333333vw;
  }
  body.top section.how .title {
    font-size: 6.4vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -0.26666667vw;
    text-align: center;
    transform: scaleX(0.98);
  }
  body.top section.how .img {
    padding: 18.66666667vw 0 0;
    text-align: center;
  }
  body.top section.how .img img,
  body.top section.how .img .lottie {
    width: 53.2vw;
  }
  body.top section.how .link {
    margin-top: 10.66666667vw;
    text-align: center;
  }
}
body.top section.works {
  min-height: 50vh;
  overflow: hidden;
}
body.top section.works * {
  user-select: none;
}
body.top section.works .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.top section.works .title {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.works .title {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.top section.works .carousel-container {
  position: relative;
}
body.top section.works .carousel-container * {
  user-select: none;
}
body.top section.works .carousel-container .main {
  position: absolute;
  width: 100%;
  height: 100%;
}
body.top section.works .carousel-container .main li {
  position: absolute;
  top: 0;
  left: 0;
}
body.top section.works .carousel-container .main a {
  display: block;
  pointer-events: none;
}
body.top section.works .carousel-container .main figure {
  position: relative;
  z-index: 1;
  mask-image: url("../images/common/edge.png");
  mask-size: cover;
}
body.top section.works .carousel-container .main figure img,
body.top section.works .carousel-container .main figure video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
body.top section.works .carousel-container .main figure video {
  opacity: 0;
}
body.top section.works .carousel-container .main figure + span {
  position: relative;
  z-index: 2;
  display: block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 1;
  color: #cfd0cf;
}
body.top section.works .carousel-container .extra {
  position: absolute;
  display: flex;
  justify-content: flex-end;
}
body.top section.works .carousel-container .extra .position {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
}
body.top section.works .carousel-container .extra .btns {
  display: flex;
}
body.top section.works .carousel-container .extra .btns span {
  display: block;
  position: relative;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 100%;
  background-size: cover;
}
body.top section.works .carousel-container .extra .btns span:before,
body.top section.works .carousel-container .extra .btns span:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
body.top section.works .carousel-container .extra .btns .next {
  transform: scaleX(-1);
}
body.top section.works .all {
  text-align: right;
}
body.top section.works .all a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.top section.works .all a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.top section.works .all a {
    font-size: 18px;
  }
  body.top section.works .all a:after {
    margin-top: 3px;
  }
  body.top section.works .all a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.top section.works .all a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.top section.works {
    margin-bottom: 400px;
  }
  body.top section.works .carousel-container {
    margin-top: 200px;
    height: 47.51461988vw;
  }
  body.top section.works .carousel-container .main li {
    width: 40.93567251vw;
    cursor: grab;
  }
  body.top section.works .carousel-container .main li a {
    will-change: transform;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    transition-duration: 500ms;
  }
  body.top section.works .carousel-container .main li:hover a {
    transform: scale(1.08);
  }
  body.top section.works .carousel-container .main figure {
    height: 29.23976608vw;
  }
  body.top section.works .carousel-container .main figure + span {
    margin-top: -1.82748538vw;
    font-size: 3.50877193vw;
  }
  body.top section.works .carousel-container .extra {
    top: 38.01169591vw;
    right: 150px;
  }
  body.top section.works .carousel-container .extra .position {
    font-size: 26px;
    height: 80px;
    margin-right: 80px;
  }
  body.top section.works .carousel-container .extra .position .spacer {
    height: 2px;
    width: 60px;
    background-color: #000000;
    margin: 0 20px;
  }
  body.top section.works .carousel-container .extra .btns {
    gap: 40px;
  }
  body.top section.works .carousel-container .extra .btns span {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
  body.top section.works .carousel-container .extra .btns span:before {
    background-color: #000000;
    opacity: 0;
    border-radius: 100%;
    transform: scale(0);
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    transition-duration: 200ms;
  }
  body.top section.works .carousel-container .extra .btns span:hover:before {
    opacity: 1;
    transform: scale(1.01);
  }
  body.top section.works .carousel-container .extra .btns span:after {
    background-image: url("../images/carousel/arrow_pc_black.png"), url("../images/carousel/arrow_pc_white.png");
    background-size: cover, 0;
  }
  body.top section.works .carousel-container .extra .btns span:hover:after {
    background-size: 0, cover;
  }
  body.top section.works .all {
    padding-right: 150px;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.works {
    margin-bottom: 37.33333333vw;
  }
  body.top section.works .carousel-container {
    margin-top: 20vw;
    height: 100vw;
  }
  body.top section.works .carousel-container .main li {
    width: 74.66666667vw;
  }
  body.top section.works .carousel-container .main figure {
    height: 53.33333333vw;
  }
  body.top section.works .carousel-container .main figure + span {
    font-size: 7.46666667vw;
    margin-top: -3.33333333vw;
  }
  body.top section.works .carousel-container .extra {
    top: 72vw;
    right: 5.33333333vw;
  }
  body.top section.works .carousel-container .extra .position {
    font-size: 4.53333333vw;
    margin-right: 22.13333333vw;
  }
  body.top section.works .carousel-container .extra .position .spacer {
    height: 2px;
    width: 10.66666667vw;
    background-color: #000000;
    margin: 0 2.66666667vw;
  }
  body.top section.works .carousel-container .extra .btns {
    gap: 5.33333333vw;
  }
  body.top section.works .carousel-container .extra .btns span {
    width: 13.33333333vw;
    height: 13.33333333vw;
  }
  body.top section.works .carousel-container .extra .btns span:after {
    background-image: url("../images/carousel/arrow_sp.png");
  }
  body.top section.works .all {
    text-align: center;
  }
}
body.top section.news .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.top section.news .title {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.news .title {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.top section.news .link a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.top section.news .link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.top section.news .link a {
    font-size: 18px;
  }
  body.top section.news .link a:after {
    margin-top: 3px;
  }
  body.top section.news .link a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.top section.news .link a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.top section.news {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  body.top section.news .title {
    margin-bottom: 200px;
  }
  body.top section.news .wrap {
    margin-bottom: 80px;
  }
  body.top section.news .wrap a {
    display: block;
  }
  body.top section.news .wrap a:not(:last-of-type) {
    margin-bottom: 40px;
  }
  body.top section.news .wrap dl {
    display: flex;
  }
  body.top section.news .wrap dl dt {
    width: 170px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    flex-shrink: 0;
  }
  body.top section.news .wrap dl dt span {
    font-size: 12px;
    letter-spacing: -0.02em;
  }
  body.top section.news .wrap dl dt span:first-of-type:after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px;
  }
  body.top section.news .wrap dl dd {
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 1.7;
    transition-duration: 0.3s;
  }
  body.top section.news .wrap a:hover dd {
    opacity: 0.7;
  }
  body.top section.news .link {
    text-align: right;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.news {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.top section.news .title {
    margin-bottom: 18.66666667vw;
  }
  body.top section.news .wrap {
    margin-bottom: 10.66666667vw;
  }
  body.top section.news .wrap a {
    display: block;
  }
  body.top section.news .wrap a:not(:last-of-type) {
    margin-bottom: 10.66666667vw;
  }
  body.top section.news .wrap dl dt {
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    margin-bottom: 1.33333333vw;
  }
  body.top section.news .wrap dl dt span {
    font-size: 10px;
    letter-spacing: -0.02em;
  }
  body.top section.news .wrap dl dt span:first-of-type:after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px;
  }
  body.top section.news .wrap dl dd {
    font-size: 3.2vw;
    letter-spacing: 0.1em;
    line-height: 2;
  }
  body.top section.news .link {
    text-align: center;
  }
}
body.top section.contact .text {
  user-select: none;
}
body.top section.contact .text input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
@media only screen and (min-width: 750px) {
  body.top section.contact {
    padding: 389px 0 226px;
  }
  body.top section.contact .title {
    font-size: 36px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -2px;
    text-align: center;
  }
  body.top section.contact .img {
    width: 384px;
    margin: 160px auto 0;
    cursor: pointer;
  }
  body.top section.contact .text {
    padding: 81px 0 0;
    text-align: center;
  }
  body.top section.contact .text span {
    position: relative;
    font-size: 18px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    display: inline-block;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  body.top section.contact .text span::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
  }
}
@media only screen and (max-width: 749px) {
  body.top section.contact {
    padding: 37.33333333vw 0 43.2vw;
  }
  body.top section.contact .title {
    font-size: 6.4vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -0.26666667vw;
    text-align: center;
  }
  body.top section.contact .img {
    width: 66.53333333vw;
    margin: 14.66666667vw auto 0;
  }
  body.top section.contact .text {
    padding: 10.66666667vw 0 0;
    text-align: center;
  }
  body.top section.contact .text span {
    position: relative;
    font-size: 4vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    display: inline-block;
    letter-spacing: -0.5px;
  }
  body.top section.contact .text span::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
  }
}
body.about h2 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about h2 {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.about h2 {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.about .inner-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.about .inner-nav ul li:after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #000000;
  transform: scaleX(0);
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
}
@media only screen and (min-width: 750px) {
  body.about .inner-nav {
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  body.about .inner-nav ul {
    flex-wrap: wrap;
  }
  body.about .inner-nav li {
    font-size: 24px;
    cursor: pointer;
  }
  body.about .inner-nav li:hover:after,
  body.about .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.about .inner-nav li:not(:first-of-type) {
    margin-left: 40px;
  }
  body.about .inner-nav li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 749px) {
  body.about .inner-nav li {
    font-size: 4.26666667vw;
    letter-spacing: -0.02em;
  }
  body.about .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.about .inner-nav li:not(:first-of-type) {
    margin-left: 4.26666667vw;
  }
  body.about .inner-nav li:not(:last-of-type) {
    margin-right: 4.26666667vw;
  }
}
@media only screen and (min-width: 750px) {
  body.about .scroll-wrapper {
    padding-top: 0;
  }
  body.about section.head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  body.about h2 {
    margin-bottom: 120px;
  }
}
@media only screen and (max-width: 749px) {
  body.about .scroll-wrapper {
    padding-top: 0vw;
  }
  body.about section.head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  body.about h2 {
    margin-bottom: 18.66666667vw;
  }
}
body.about section.mission .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.mission .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.mission .title {
    font-size: 5.86666667vw;
  }
}
@media only screen and (min-width: 750px) {
  body.about section.mission {
    padding: 110px 0 0;
    margin-bottom: 300px;
  }
  body.about section.mission .text {
    padding: 80px 0 0;
    width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  body.about section.mission .text p {
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 2;
    word-break: break-all;
    text-align: justify;
  }
  body.about section.mission .text p.lead {
    margin-top: 50px;
    font-weight: bold;
    text-align: center;
  }
  body.about section.mission .text p:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.mission {
    padding: 18.66666667vw 13.33333333vw 0;
    margin-bottom: 22.66666667vw;
  }
  body.about section.mission .text {
    padding: 10.66666667vw 0 0;
  }
  body.about section.mission .text p {
    font-size: 3.2vw;
    letter-spacing: 0.1em;
    line-height: 2;
    word-break: break-all;
    text-align: justify;
  }
  body.about section.mission .text p.lead {
    margin-top: 8vw;
    font-weight: bold;
    text-align: center;
  }
  body.about section.mission .text p:not(:last-of-type) {
    margin-bottom: 2.66666667vw;
  }
}
body.about section.founder .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.founder .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.founder .title {
    font-size: 5.86666667vw;
  }
}
@media only screen and (min-width: 750px) {
  body.about section.founder {
    padding: 100px 0 0;
    margin-bottom: 300px;
  }
  body.about section.founder ul {
    padding: 70px 0 0;
  }
  body.about section.founder li {
    padding: 140px 0 0;
    display: flex;
    justify-content: center;
  }
  body.about section.founder li.-left {
    flex-direction: row-reverse;
  }
  body.about section.founder .img-wrapper {
    flex-shrink: 0;
  }
  body.about section.founder .img-wrapper img {
    width: 396px;
  }
  body.about section.founder .text-wrapper {
    padding-left: 64px;
    width: auto;
  }
  body.about section.founder .text-wrapper.-left {
    padding-left: 0;
    padding-right: 64px;
  }
  body.about section.founder dl {
    width: 411px;
  }
  body.about section.founder dl.en dt {
    flex-wrap: wrap;
  }
  body.about section.founder dl.en dt .name {
    width: 100%;
  }
  body.about section.founder dt {
    display: flex;
    align-items: center;
  }
  body.about section.founder .name {
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 3.5px;
    display: inline-block;
    padding-right: 16px;
  }
  body.about section.founder .role1 {
    font-size: 12px;
    font-weight: 900;
    letter-spacing: -0.5px;
    display: inline-block;
    padding-top: 2px;
  }
  body.about section.founder .slash {
    padding-left: 9px;
  }
  body.about section.founder .role2 {
    font-size: 14px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -0.5px;
    display: inline-block;
    padding-top: 4px;
    padding-left: 9px;
  }
  body.about section.founder dd {
    padding: 20px 0 0;
    font-size: 14px;
    line-height: 1.9;
    letter-spacing: -0.2px;
    text-align: justify;
    text-justify: inter-ideograph;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
@media only screen and (min-width: 750px) and (max-width: 950px) {
  body.about section.founder li {
    flex-direction: column !important;
    align-items: center;
  }
  body.about section.founder .img-wrapper {
    width: 70%;
  }
  body.about section.founder .img-wrapper img {
    width: 100%;
  }
  body.about section.founder .text-wrapper {
    padding-left: 0;
    padding-top: 40px;
    width: 100%;
  }
  body.about section.founder .text-wrapper.-left {
    padding-left: 0px;
    padding-right: 0px;
  }
  body.about section.founder dl {
    width: 70%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.founder {
    margin-bottom: 22.66666667vw;
    padding: 22.66666667vw 13.33333333vw 0;
  }
  body.about section.founder .title {
    font-size: 6.4vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -2px;
    text-align: center;
  }
  body.about section.founder li {
    padding: 21.33333333vw 0 0;
  }
  body.about section.founder .img-wrapper img {
    width: 100%;
  }
  body.about section.founder .text-wrapper {
    padding: 10.66666667vw 0 0;
  }
  body.about section.founder dl dt {
    flex-wrap: wrap;
  }
  body.about section.founder dl dt .name {
    width: 100%;
  }
  body.about section.founder dt {
    display: flex;
    align-items: center;
  }
  body.about section.founder span.name {
    font-size: 4.26666667vw;
    font-weight: bold;
    letter-spacing: 0.66666667vw;
    display: inline-block;
  }
  body.about section.founder span.name.js-textEn {
    letter-spacing: 0px;
  }
  body.about section.founder span.role1 {
    font-size: 2.93333333vw;
    font-weight: 900;
    letter-spacing: -0.5px;
    display: inline-block;
    padding-top: 0.93333333vw;
  }
  body.about section.founder span.slash {
    padding-left: 1.33333333vw;
    padding-right: 1.33333333vw;
  }
  body.about section.founder .role2 {
    font-size: 2.93333333vw;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    letter-spacing: -0.5px;
    display: inline-block;
    padding-top: 1.06666667vw;
  }
  body.about section.founder dd {
    padding: 6.66666667vw 0 0;
    font-size: 3.2vw;
    line-height: 2.1;
    letter-spacing: 0.26666667vw;
    text-align: justify;
    text-justify: inter-ideograph;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
body.about section.company .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.company .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.company .title {
    font-size: 5.86666667vw;
  }
}
@media only screen and (min-width: 750px) {
  body.about section.company {
    padding: 100px 0 0;
    margin-bottom: 300px;
  }
  body.about section.company .info {
    width: 475px;
    padding: 120px 0 0;
    margin: 0 auto 120px;
  }
  body.about section.company .info dl {
    display: flex;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 1.7;
  }
  body.about section.company .info dl:not(:last-of-type) {
    margin-bottom: 25px;
  }
  body.about section.company .info dl dt {
    width: 170px;
    flex-shrink: 0;
  }
  body.about section.company .map {
    width: 100%;
    max-width: 1368px;
    height: 300px;
    margin: 0 auto 0;
  }
  body.about section.company .map img {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.company {
    padding: 14.66666667vw 0 0;
    margin-bottom: 22.66666667vw;
  }
  body.about section.company .info {
    padding: 0vw 13.33333333vw 0;
    margin-top: 16.93333333vw;
    margin-bottom: 16vw;
  }
  body.about section.company .info dl {
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    line-height: 1.4;
  }
  body.about section.company .info dl:not(:last-of-type) {
    margin-bottom: 6.66666667vw;
  }
  body.about section.company .info dl dt {
    margin-bottom: 2.66666667vw;
  }
  body.about section.company .map {
    width: 100%;
    height: 66.66666667vw;
    background-color: lightgray;
  }
  body.about section.company .map img {
    display: block;
    width: 100%;
  }
}
body.about section.contact input {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  border: 1px solid #000000;
}
body.about section.contact textarea {
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  border: 1px solid #000000;
}
body.about section.contact .title {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.contact .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.contact .title {
    font-size: 5.86666667vw;
  }
}
body.about section.contact .form-wrapper {
  position: relative;
  overflow: hidden;
}
body.about section.contact .form-wrapper form {
  width: 100%;
  transition-duration: 0.5s;
  transition-property: transform, height;
}
body.about section.contact .form-wrapper form p.note span {
  display: none;
  margin-top: 25px;
  color: #c70000;
}
body.about section.contact .form-wrapper form p.note.error span {
  display: block;
}
body.about section.contact .form-wrapper form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  background-color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.contact .form-wrapper form button {
    width: 240px;
    height: 56px;
    font-size: 18px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: color, background-color;
  }
  body.about section.contact .form-wrapper form button:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.contact .form-wrapper form button {
    width: 42.66666667vw;
    height: 10.66666667vw;
    font-size: 4vw;
  }
}
body.about section.contact .form-wrapper form dl.error dd input,
body.about section.contact .form-wrapper form dl.error dd textarea {
  border: 1px solid #c70000;
}
body.about section.contact .form-wrapper form .privacy.error label:before {
  border: 1px solid #c70000 !important;
}
body.about section.contact .form-wrapper .confirm {
  transform: translateX(110%);
  transition-duration: 0.5s;
  transition-property: transform, height;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
body.about section.contact .form-wrapper .confirm .buttons {
  display: flex;
}
body.about section.contact .form-wrapper .confirm .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  background-color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.about section.contact .form-wrapper .confirm .buttons button {
    width: 240px;
    height: 56px;
    font-size: 18px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: color, background-color;
  }
  body.about section.contact .form-wrapper .confirm .buttons button:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.contact .form-wrapper .confirm .buttons button {
    width: 42.66666667vw;
    height: 10.66666667vw;
    font-size: 4vw;
  }
}
body.about section.contact .form-wrapper .thanks {
  transform: translateX(110%);
  transition-duration: 0.5s;
  transition-property: transform, height;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media only screen and (min-width: 750px) {
  body.about section.contact {
    padding: 80px 0 0;
  }
  body.about section.contact .form-wrapper {
    width: 680px;
    margin: 0 auto 0;
  }
  body.about section.contact .form-wrapper form p.note {
    padding: 80px 0;
    text-align: center;
  }
  body.about section.contact .form-wrapper form dl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  body.about section.contact .form-wrapper form dl dt {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  body.about section.contact .form-wrapper form dl dt span {
    font-size: 10px;
    margin-top: 5px;
    letter-spacing: 1px;
  }
  body.about section.contact .form-wrapper form dl dd {
    width: 520px;
    flex-shrink: 0;
  }
  body.about section.contact .form-wrapper form dl.en dt {
    letter-spacing: 0.01em;
  }
  body.about section.contact .form-wrapper form dl.en dd {
    width: 480px;
  }
  body.about section.contact .form-wrapper form dl.input {
    height: 56px;
  }
  body.about section.contact .form-wrapper form dl.input dt {
    justify-content: center;
  }
  body.about section.contact .form-wrapper form dl.input input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 1.7;
    background-color: #ffffff;
  }
  body.about section.contact .form-wrapper form dl.textarea {
    height: 240px;
  }
  body.about section.contact .form-wrapper form dl.textarea dt {
    padding-top: 10px;
  }
  body.about section.contact .form-wrapper form dl.textarea textarea {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 1.7;
    background-color: #ffffff;
  }
  body.about section.contact .form-wrapper form .privacy {
    text-align: center;
  }
  body.about section.contact .form-wrapper form .privacy input {
    display: none;
  }
  body.about section.contact .form-wrapper form .privacy label {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    font-size: 13px;
    letter-spacing: 0.1em;
    padding-left: 40px;
    cursor: pointer;
  }
  body.about section.contact .form-wrapper form .privacy label:before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #000000;
    position: absolute;
    left: 0;
    top: 0;
  }
  body.about section.contact .form-wrapper form .privacy label:after {
    content: "";
    display: block;
    width: 10px;
    height: 20px;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    transform: rotate(45deg);
    position: absolute;
    left: 10px;
    top: 2px;
    opacity: 0;
  }
  body.about section.contact .form-wrapper form .privacy input:checked + label:after {
    opacity: 1;
  }
  body.about section.contact .form-wrapper form button {
    margin: 80px auto 0;
  }
  body.about section.contact .form-wrapper .confirm p.note {
    padding: 80px 0;
    text-align: center;
  }
  body.about section.contact .form-wrapper .confirm dl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  body.about section.contact .form-wrapper .confirm dl dt {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  body.about section.contact .form-wrapper .confirm dl dd {
    width: 520px;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 1.7;
  }
  body.about section.contact .form-wrapper .confirm dl.en dt {
    letter-spacing: 0.01em;
  }
  body.about section.contact .form-wrapper .confirm dl.en dd {
    width: 480px;
  }
  body.about section.contact .form-wrapper .confirm .buttons {
    justify-content: center;
    margin: 80px auto 0;
  }
  body.about section.contact .form-wrapper .confirm .buttons button:first-of-type {
    margin-right: 20px;
  }
  body.about section.contact .form-wrapper .thanks {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  body.about section.contact .form-wrapper .thanks p {
    font-size: 14px;
    line-height: 2;
    text-align: center;
  }
}
@media only screen and (max-width: 749px) {
  body.about section.contact {
    padding-top: 14.66666667vw;
  }
  body.about section.contact .form-wrapper p.note {
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 16.93333333vw;
    margin-bottom: 9.86666667vw;
  }
  body.about section.contact .form-wrapper form {
    padding-left: 5.33333333vw;
    padding-right: 5.33333333vw;
  }
  body.about section.contact .form-wrapper form dl dt {
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    margin-bottom: 2.66666667vw;
  }
  body.about section.contact .form-wrapper form dl dt span {
    font-size: 2.66666667vw;
    letter-spacing: 0.1em;
    margin-left: 3.2vw;
  }
  body.about section.contact .form-wrapper form dl.input {
    margin-bottom: 6.26666667vw;
  }
  body.about section.contact .form-wrapper form dl.input dt {
    justify-content: center;
  }
  body.about section.contact .form-wrapper form dl.input input {
    display: block;
    width: 100%;
    height: 10.66666667vw;
    padding: 2.66666667vw;
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    line-height: 1.7;
    background-color: #ffffff;
  }
  body.about section.contact .form-wrapper form dl.textarea {
    margin-bottom: 10.66666667vw;
  }
  body.about section.contact .form-wrapper form dl.textarea textarea {
    display: block;
    width: 100%;
    height: 42.66666667vw;
    padding: 5.33333333vw;
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    line-height: 1.8;
    background-color: #ffffff;
  }
  body.about section.contact .form-wrapper form .privacy input {
    display: none;
  }
  body.about section.contact .form-wrapper form .privacy label {
    display: inline-block;
    line-height: 1.6;
    position: relative;
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
    padding-left: 9.33333333vw;
    cursor: pointer;
  }
  body.about section.contact .form-wrapper form .privacy label:before {
    content: "";
    display: block;
    width: 6.66666667vw;
    height: 6.66666667vw;
    background-color: #ffffff;
    border: 0.26666667vw solid #000000;
    position: absolute;
    left: 0;
    top: 1.06666667vw;
  }
  body.about section.contact .form-wrapper form .privacy label:after {
    content: "";
    display: block;
    width: 2vw;
    height: 4.66666667vw;
    border-right: 0.53333333vw solid #000000;
    border-bottom: 0.53333333vw solid #000000;
    transform: rotate(45deg);
    position: absolute;
    left: 2.4vw;
    top: 1.6vw;
    opacity: 0;
  }
  body.about section.contact .form-wrapper form .privacy input:checked + label:after {
    opacity: 1;
  }
  body.about section.contact .form-wrapper form button {
    margin-top: 11.73333333vw;
    margin-left: auto;
    margin-right: auto;
  }
  body.about section.contact .form-wrapper .confirm {
    padding-left: 5.33333333vw;
    padding-right: 5.33333333vw;
  }
  body.about section.contact .form-wrapper .confirm dl {
    margin-bottom: 6.26666667vw;
    font-size: 2.93333333vw;
    letter-spacing: 0.1em;
  }
  body.about section.contact .form-wrapper .confirm .buttons button:first-of-type {
    margin-right: 5.33333333vw;
  }
  body.about section.contact .form-wrapper .thanks {
    padding-left: 5.33333333vw;
    padding-right: 5.33333333vw;
    padding-top: 13.33333333vw;
    padding-bottom: 13.33333333vw;
  }
  body.about section.contact .form-wrapper .thanks p {
    font-size: 2.93333333vw;
    line-height: 2;
    text-align: center;
  }
}
body.works h2 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.works h2 {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.works h2 {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.works .inner-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.works .inner-nav ul li:after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #000000;
  transform: scaleX(0);
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
}
@media only screen and (min-width: 750px) {
  body.works .inner-nav {
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  body.works .inner-nav ul {
    flex-wrap: wrap;
  }
  body.works .inner-nav li {
    font-size: 24px;
    cursor: pointer;
  }
  body.works .inner-nav li:hover:after,
  body.works .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.works .inner-nav li:not(:first-of-type) {
    margin-left: 40px;
  }
  body.works .inner-nav li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 749px) {
  body.works .inner-nav li {
    font-size: 4.26666667vw;
    letter-spacing: -0.02em;
  }
  body.works .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.works .inner-nav li:not(:first-of-type) {
    margin-left: 4.26666667vw;
  }
  body.works .inner-nav li:not(:last-of-type) {
    margin-right: 4.26666667vw;
  }
}
@media only screen and (min-width: 750px) {
  body.works h2 {
    margin-bottom: 120px;
  }
}
@media only screen and (max-width: 749px) {
  body.works h2 {
    margin-bottom: 18.66666667vw;
  }
}
body.works section.contents ul li a {
  display: block;
  position: relative;
}
body.works section.contents ul li figure {
  mask-image: url("../images/common/edge.png");
  mask-size: cover;
  padding-top: 70%;
  position: relative;
}
body.works section.contents ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.works section.contents ul li figure video {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.works section.contents ul li .text {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body.works section.contents ul li .text .info {
  position: relative;
}
body.works section.contents ul li .text .info span:not(:last-of-type):after {
  content: "|";
}
@media only screen and (min-width: 750px) {
  body.works section.contents ul li .text {
    padding: 10px;
  }
  body.works section.contents ul li .text p {
    font-size: 3.28947368vw;
    line-height: 1;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
  }
  body.works section.contents ul li .text .info {
    font-size: 14px;
  }
  body.works section.contents ul li .text .info span:after {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 750px) and (min-width: 1368px) {
  body.works section.contents ul li .text p {
    font-size: 45px;
  }
}
@media only screen and (max-width: 749px) {
  body.works section.contents ul li .text {
    padding: 2.66666667vw;
  }
  body.works section.contents ul li .text p {
    font-size: 5.33333333vw;
    line-height: 1;
    letter-spacing: -0.02em;
    margin-bottom: 1.33333333vw;
  }
  body.works section.contents ul li .text .info {
    font-size: 3.2vw;
  }
  body.works section.contents ul li .text .info span:after {
    padding-left: 1.33333333vw;
    padding-right: 1.33333333vw;
  }
}
body.works section.contents .more {
  text-align: center;
}
body.works section.contents .more span {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.works section.contents .more span:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.works section.contents .more span {
    font-size: 18px;
  }
  body.works section.contents .more span:after {
    margin-top: 3px;
  }
  body.works section.contents .more span:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.works section.contents .more span {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.works section.contents ul {
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 120px;
  }
  body.works section.contents ul li {
    width: calc(50% - 20px);
  }
  body.works section.contents ul li:not(:nth-of-type(-n+2)) {
    margin-top: 40px;
  }
  body.works section.contents ul li:nth-of-type(odd) {
    margin-right: 40px;
  }
  body.works section.contents ul li .text {
    opacity: 0;
    transition-duration: 0.2s;
    transform: translateY(-3px);
    pointer-events: none;
  }
  body.works section.contents ul figure {
    transition-duration: 0.4s;
    will-change: transform;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  body.works section.contents ul figure:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
    transition-duration: 0.4s;
  }
  body.works section.contents ul li:hover figure {
    transform: scale(1.02);
  }
  body.works section.contents ul li:hover figure:before {
    opacity: 1;
  }
  body.works section.contents ul li:hover .text {
    transition-delay: 0.2s;
    opacity: 1;
    transform: none;
  }
  body.works section.contents .more {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 749px) {
  body.works section.contents ul {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    margin-top: 18.66666667vw;
  }
  body.works section.contents ul li:not(:first-of-type) {
    margin-top: 10.66666667vw;
  }
  body.works section.contents ul li .text {
    opacity: 0;
    transition-duration: 0.4s;
    transform: translateY(-2px);
  }
  body.works section.contents ul li figure:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
    transition-duration: 0.4s;
  }
  body.works section.contents ul li.show-info figure:before {
    opacity: 1;
  }
  body.works section.contents ul li.show-info .text {
    transition-delay: 0.2s;
    opacity: 1;
    transform: none;
  }
  body.works section.contents .more {
    margin-top: 16vw;
  }
}
body.works-sub section.intro header {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.works-sub section.intro header h2 {
  text-align: center;
}
body.works-sub section.intro header ul {
  text-align: center;
}
body.works-sub section.intro header ul li {
  display: inline;
}
body.works-sub section.intro header ul li:after {
  content: "|";
}
body.works-sub section.intro header ul li:last-of-type:after {
  display: none;
}
body.works-sub section.intro .description p {
  line-height: 2;
  letter-spacing: 0.1em;
}
body.works-sub section.intro .link a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.works-sub section.intro .link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.works-sub section.intro .link a {
    font-size: 18px;
  }
  body.works-sub section.intro .link a:after {
    margin-top: 3px;
  }
  body.works-sub section.intro .link a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.intro .link a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.works-sub section.intro {
    width: 100%;
    max-width: 1088px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
  body.works-sub section.intro header {
    margin-bottom: 120px;
  }
  body.works-sub section.intro header h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  body.works-sub section.intro header ul {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  body.works-sub section.intro header ul li:after {
    padding-left: 10px;
    padding-right: 10px;
  }
  body.works-sub section.intro .kv {
    margin-bottom: 120px;
  }
  body.works-sub section.intro .kv img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  body.works-sub section.intro .description {
    margin-bottom: 80px;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  body.works-sub section.intro .description p {
    font-size: 14px;
  }
  body.works-sub section.intro .link {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.intro {
    margin-bottom: 18.66666667vw;
  }
  body.works-sub section.intro header {
    margin-bottom: 18.66666667vw;
  }
  body.works-sub section.intro header h2 {
    font-size: 5.86666667vw;
    line-height: 1.08;
    margin-bottom: 3.2vw;
  }
  body.works-sub section.intro header ul {
    font-size: 2.93333333vw;
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.works-sub section.intro header ul li:after {
    padding-left: 2.66666667vw;
    padding-right: 2.66666667vw;
  }
  body.works-sub section.intro .kv {
    margin-bottom: 18.66666667vw;
  }
  body.works-sub section.intro .description {
    margin-bottom: 26.66666667vw;
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.works-sub section.intro .description p {
    font-size: 3.2vw;
  }
  body.works-sub section.intro .link {
    text-align: center;
  }
  body.works-sub section.intro .link a {
    display: inline-block;
    font-size: 18px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }
  body.works-sub section.intro .link a:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000000;
  }
}
body.works-sub section.main article h3,
body.works-sub section.main article p {
  letter-spacing: 0.1em;
}
body.works-sub section.main article h3 {
  font-weight: normal;
  line-height: 1.5;
}
body.works-sub section.main article p {
  line-height: 2;
}
body.works-sub section.main article .video {
  padding-top: 56.25%;
  position: relative;
}
body.works-sub section.main article .video iframe {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
body.works-sub section.main .team {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
}
body.works-sub section.main .team h4 {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  text-align: center;
}
body.works-sub section.main .team .overview {
  padding: 40px 70px 70px;
}
body.works-sub section.main .team .overview dl dt {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.02em;
}
body.works-sub section.main .team .overview dl dd {
  letter-spacing: 0.05em;
}
body.works-sub section.main .links .sns {
  display: flex;
}
body.works-sub section.main .links .sns a {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (min-width: 750px) {
  body.works-sub section.main .links .sns a {
    height: 23px;
    margin-right: 40px;
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.main .links .sns {
    justify-content: space-between;
  }
  body.works-sub section.main .links .sns a {
    height: 5.73333333vw;
  }
}
body.works-sub section.main .links .contact a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.works-sub section.main .links .contact a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.works-sub section.main .links .contact a {
    font-size: 18px;
  }
  body.works-sub section.main .links .contact a:after {
    margin-top: 3px;
  }
  body.works-sub section.main .links .contact a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.main .links .contact a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.works-sub section.main {
    width: 100%;
    max-width: 1088px;
    margin-left: auto;
    margin-right: auto;
  }
  body.works-sub section.main article:not(:last-of-type) {
    margin-bottom: 160px;
  }
  body.works-sub section.main article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
  body.works-sub section.main article .video {
    margin-bottom: 120px;
  }
  body.works-sub section.main article h3,
  body.works-sub section.main article p {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  body.works-sub section.main article h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  body.works-sub section.main article p {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 15px;
  }
  body.works-sub section.main .team {
    margin-top: 200px;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    margin-bottom: 80px;
  }
  body.works-sub section.main .team h4 {
    font-size: 24px;
  }
  body.works-sub section.main .team .overview dl {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  body.works-sub section.main .team .overview dl dt {
    width: 175px;
    padding-right: 15px;
    padding-top: 2px;
    flex-shrink: 0;
    font-size: 11px;
  }
  body.works-sub section.main .team .overview dl dd {
    font-size: 12px;
  }
  body.works-sub section.main .links {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.main article:not(:last-of-type) {
    margin-bottom: 40vw;
  }
  body.works-sub section.main article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18.66666667vw;
  }
  body.works-sub section.main article .video {
    margin-bottom: 18.66666667vw;
  }
  body.works-sub section.main article h3,
  body.works-sub section.main article p {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.works-sub section.main article h3 {
    font-size: 4vw;
    margin-bottom: 6.66666667vw;
  }
  body.works-sub section.main article p {
    font-size: 3.2vw;
    margin-bottom: 2.66666667vw;
  }
  body.works-sub section.main .team {
    width: 73.33333333vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40vw;
    padding-top: 5.33333333vw;
    margin-bottom: 10.66666667vw;
  }
  body.works-sub section.main .team h4 {
    font-size: 4.26666667vw;
  }
  body.works-sub section.main .team .overview {
    padding: 5.33333333vw;
  }
  body.works-sub section.main .team .overview dl {
    line-height: 1.4;
    margin-bottom: 5.33333333vw;
  }
  body.works-sub section.main .team .overview dl dt {
    font-size: 2.66666667vw;
  }
  body.works-sub section.main .team .overview dl dd {
    font-size: 2.93333333vw;
  }
  body.works-sub section.main .links {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    margin-bottom: 33.33333333vw;
  }
  body.works-sub section.main .links .contact {
    margin-top: 10.66666667vw;
    text-align: center;
  }
}
body.works-sub section.related h3 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.works-sub section.related ul li a {
  display: block;
}
body.works-sub section.related ul li figure {
  mask-image: url("../images/common/edge.png");
  mask-size: cover;
  padding-top: 70%;
  position: relative;
}
body.works-sub section.related ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.works-sub section.related ul li figure video {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.works-sub section.related ul li .text {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body.works-sub section.related ul li .text .info {
  position: relative;
}
body.works-sub section.related ul li .text .info span:not(:last-of-type):after {
  content: "|";
}
@media only screen and (min-width: 750px) {
  body.works-sub section.related ul li .text {
    padding: 10px;
  }
  body.works-sub section.related ul li .text p {
    font-size: 2.63157895vw;
    line-height: 1;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
  }
  body.works-sub section.related ul li .text .info {
    font-size: 14px;
  }
  body.works-sub section.related ul li .text .info span:after {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 750px) and (min-width: 1368px) {
  body.works-sub section.related ul li .text p {
    font-size: 36px;
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.related ul li .text {
    padding: 2.66666667vw;
  }
  body.works-sub section.related ul li .text p {
    font-size: 5.33333333vw;
    line-height: 1;
    letter-spacing: -0.02em;
    margin-bottom: 1.33333333vw;
  }
  body.works-sub section.related ul li .text .info {
    font-size: 3.2vw;
  }
  body.works-sub section.related ul li .text .info span:after {
    padding-left: 1.33333333vw;
    padding-right: 1.33333333vw;
  }
}
body.works-sub section.related .more {
  text-align: center;
}
body.works-sub section.related .more a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.works-sub section.related .more a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.works-sub section.related .more a {
    font-size: 18px;
  }
  body.works-sub section.related .more a:after {
    margin-top: 3px;
  }
  body.works-sub section.related .more a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.related .more a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.works-sub section.related h3 {
    font-size: 30px;
    margin-bottom: 80px;
  }
  body.works-sub section.related ul {
    max-width: 948px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  body.works-sub section.related ul li {
    width: 50%;
  }
  body.works-sub section.related ul li:nth-of-type(1) {
    margin-right: 40px;
  }
  body.works-sub section.related ul li .text {
    opacity: 0;
    transition-duration: 0.2s;
    transform: translateY(-3px);
    pointer-events: none;
  }
  body.works-sub section.related ul li figure {
    transition-duration: 0.4s;
    will-change: transform;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  body.works-sub section.related ul li figure:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
    transition-duration: 0.4s;
  }
  body.works-sub section.related ul li:hover figure {
    transform: scale(1.02);
  }
  body.works-sub section.related ul li:hover figure:before {
    opacity: 1;
  }
  body.works-sub section.related ul li:hover .text {
    transition-delay: 0.2s;
    opacity: 1;
    transform: none;
  }
  body.works-sub section.related .more {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 749px) {
  body.works-sub section.related h3 {
    font-size: 5.33333333vw;
    margin-bottom: 10.66666667vw;
  }
  body.works-sub section.related ul {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.works-sub section.related ul li:nth-of-type(1) {
    margin-bottom: 10.66666667vw;
  }
  body.works-sub section.related ul li .text {
    opacity: 0;
    transition-duration: 0.4s;
    transform: translateY(-2px);
  }
  body.works-sub section.related ul li figure:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
    transition-duration: 0.4s;
  }
  body.works-sub section.related ul li.show-info figure:before {
    opacity: 1;
  }
  body.works-sub section.related ul li.show-info .text {
    transition-delay: 0.2s;
    opacity: 1;
    transform: none;
  }
  body.works-sub section.related .more {
    margin-top: 13.33333333vw;
  }
}
body.news h2 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.news h2 {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.news h2 {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.news .inner-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.news .inner-nav ul li:after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #000000;
  transform: scaleX(0);
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
}
@media only screen and (min-width: 750px) {
  body.news .inner-nav {
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  body.news .inner-nav ul {
    flex-wrap: wrap;
  }
  body.news .inner-nav li {
    font-size: 24px;
    cursor: pointer;
  }
  body.news .inner-nav li:hover:after,
  body.news .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.news .inner-nav li:not(:first-of-type) {
    margin-left: 24px;
  }
  body.news .inner-nav li:not(:last-of-type) {
    margin-right: 24px;
  }
}
@media only screen and (max-width: 749px) {
  body.news .inner-nav li {
    font-size: 4.26666667vw;
    letter-spacing: -0.02em;
  }
  body.news .inner-nav li.active:after {
    transform: scaleX(1);
  }
  body.news .inner-nav li:not(:first-of-type) {
    margin-left: 4.26666667vw;
  }
  body.news .inner-nav li:not(:last-of-type) {
    margin-right: 4.26666667vw;
  }
}
body.news form input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  background-color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.news form input[type="submit"] {
    width: 120px;
    height: 48px;
    font-size: 18px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: color, background-color;
  }
  body.news form input[type="submit"]:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
@media only screen and (max-width: 749px) {
  body.news form input[type="submit"] {
    width: 26.66666667vw;
    height: 9.6vw;
    font-size: 4vw;
  }
}
body.news form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  background-color: #ffffff;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 750px) {
  body.news form select {
    width: 240px;
    height: 48px;
    font-size: 18px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: color, background-color;
  }
  body.news form select:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
@media only screen and (max-width: 749px) {
  body.news form select {
    width: 42.66666667vw;
    height: 9.6vw;
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.news h2 {
    margin-bottom: 120px;
  }
  body.news form {
    display: flex;
    justify-content: flex-end;
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  body.news form select {
    padding-left: 100px;
    margin-right: 24px;
    background-position: right 20px top 15px;
    background-image: url("../images/icon/arw_bottom_bk.svg"), url("../images/icon/arw_bottom_wh.svg");
    background-size: 12px 12px, 0;
  }
  body.news form select:hover {
    background-size: 0, 12px 12px;
  }
}
@media only screen and (max-width: 749px) {
  body.news h2 {
    margin-bottom: 18.66666667vw;
  }
  body.news .inner-nav {
    margin-bottom: 5.33333333vw;
  }
  body.news .inner-nav ul {
    justify-content: start;
    overflow-x: scroll;
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    padding-bottom: 2vw;
  }
  body.news .inner-nav ul li {
    flex-shrink: 0;
  }
  body.news form {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    display: flex;
    justify-content: space-between;
  }
  body.news form select {
    padding-left: 17.33333333vw;
    background-image: url("../images/icon/arw_bottom_bk.svg");
    background-size: 3.73333333vw 2.26666667vw;
    background-position: right 3.33333333vw top 2.66666667vw;
  }
}
body.news section.contents ul li a {
  display: block;
}
body.news section.contents ul li figure {
  mask-image: url("../images/common/edge.png");
  mask-size: cover;
  padding-top: 70%;
  position: relative;
  margin-bottom: 25px;
}
body.news section.contents ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.news section.contents .more {
  text-align: center;
}
body.news section.contents .more span {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.news section.contents .more span:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.news section.contents .more span {
    font-size: 18px;
  }
  body.news section.contents .more span:after {
    margin-top: 3px;
  }
  body.news section.contents .more span:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.news section.contents .more span {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.news section.contents {
    margin-top: 120px;
  }
  body.news section.contents ul {
    max-width: 1088px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
  }
  body.news section.contents ul li {
    width: calc(50% - 20px);
  }
  body.news section.contents ul li:not(:nth-of-type(-n+2)) {
    margin-top: 40px;
  }
  body.news section.contents ul li:nth-of-type(odd) {
    margin-right: 40px;
  }
  body.news section.contents ul li figure {
    margin-bottom: 25px;
  }
  body.news section.contents ul li p.title {
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-bottom: 14px;
  }
  body.news section.contents ul li .info {
    font-size: 12px;
    letter-spacing: 0.02em;
    font-weight: bold;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  body.news section.contents ul li .info .partition {
    margin-left: 10px;
    margin-right: 10px;
  }
  body.news section.contents ul li figure {
    transition-duration: 0.4s;
    will-change: transform;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  body.news section.contents ul li:hover figure {
    transform: scale(1.02);
  }
  body.news section.contents .more {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 749px) {
  body.news section.contents {
    margin-top: 16vw;
  }
  body.news section.contents ul {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.news section.contents ul li:not(:first-of-type) {
    margin-top: 10.66666667vw;
  }
  body.news section.contents ul li figure {
    margin-bottom: 5.33333333vw;
  }
  body.news section.contents ul li p.title {
    font-size: 3.2vw;
    letter-spacing: 0.1em;
    line-height: 1.65;
    margin-bottom: 4vw;
  }
  body.news section.contents ul li .info {
    font-size: 2.4vw;
    letter-spacing: 0.02em;
    font-weight: bold;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  body.news section.contents ul li .info .partition {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  body.news section.contents .more {
    margin-top: 10.66666667vw;
  }
}
body.news-sub section.intro .title {
  letter-spacing: -0.02em;
}
body.news-sub section.intro .title h2 {
  text-align: center;
  font-weight: normal;
}
body.news-sub section.intro .title ul {
  display: flex;
  justify-content: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
}
body.news-sub section.intro .title ul li:after {
  content: "|";
}
body.news-sub section.intro .title ul li:last-of-type:after {
  display: none;
}
body.news-sub section.intro .description p {
  line-height: 2;
  letter-spacing: 0.1em;
}
body.news-sub section.intro .link a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.news-sub section.intro .link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.news-sub section.intro .link a {
    font-size: 18px;
  }
  body.news-sub section.intro .link a:after {
    margin-top: 3px;
  }
  body.news-sub section.intro .link a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.intro .link a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.news-sub section.intro {
    width: 100%;
    max-width: 1088px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
  }
  body.news-sub section.intro .title {
    margin-bottom: 120px;
  }
  body.news-sub section.intro .title h2 {
    font-size: 28px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  body.news-sub section.intro .title ul {
    font-size: 16px;
  }
  body.news-sub section.intro .title ul li:after {
    padding-left: 10px;
    padding-right: 10px;
  }
  body.news-sub section.intro .kv {
    margin-bottom: 120px;
  }
  body.news-sub section.intro .description {
    margin-bottom: 80px;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  body.news-sub section.intro .description p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.intro {
    margin-bottom: 37.33333333vw;
  }
  body.news-sub section.intro .title {
    margin-bottom: 18.66666667vw;
  }
  body.news-sub section.intro .title h2 {
    width: 73.33333333vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 4.53333333vw;
    line-height: 1.4;
    margin-bottom: 2.66666667vw;
  }
  body.news-sub section.intro .title ul {
    font-size: 2.93333333vw;
  }
  body.news-sub section.intro .title ul li:after {
    padding-left: 2.66666667vw;
    padding-right: 2.66666667vw;
  }
  body.news-sub section.intro .kv {
    margin-bottom: 18.66666667vw;
  }
  body.news-sub section.intro .description {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.news-sub section.intro .description p {
    font-size: 3.2vw;
  }
  body.news-sub section.intro .link {
    text-align: center;
  }
  body.news-sub section.intro .link a {
    display: inline-block;
    font-size: 18px;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }
  body.news-sub section.intro .link a:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000000;
  }
}
body.news-sub section.main article h3,
body.news-sub section.main article p {
  letter-spacing: 0.1em;
}
body.news-sub section.main article h3 {
  font-weight: normal;
  line-height: 1.5;
}
body.news-sub section.main article p {
  line-height: 2;
}
body.news-sub section.main .team {
  background-color: #ffffff;
}
body.news-sub section.main .team h4 {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  text-align: center;
}
body.news-sub section.main .links .sns {
  display: flex;
}
body.news-sub section.main .links .sns a {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (min-width: 750px) {
  body.news-sub section.main .links .sns a {
    height: 23px;
    margin-right: 40px;
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.main .links .sns {
    justify-content: space-between;
  }
  body.news-sub section.main .links .sns a {
    height: 5.73333333vw;
  }
}
body.news-sub section.main .links .contact a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.news-sub section.main .links .contact a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.news-sub section.main .links .contact a {
    font-size: 18px;
  }
  body.news-sub section.main .links .contact a:after {
    margin-top: 3px;
  }
  body.news-sub section.main .links .contact a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.main .links .contact a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.news-sub section.main {
    width: 100%;
    max-width: 1088px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px;
  }
  body.news-sub section.main article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
  body.news-sub section.main article h3,
  body.news-sub section.main article p {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  body.news-sub section.main article h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  body.news-sub section.main article p {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 15px;
  }
  body.news-sub section.main .links {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.main article:not(:last-of-type) {
    margin-bottom: 40vw;
  }
  body.news-sub section.main article img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18.66666667vw;
  }
  body.news-sub section.main article h3,
  body.news-sub section.main article p {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.news-sub section.main article h3 {
    font-size: 4vw;
    margin-bottom: 6.66666667vw;
  }
  body.news-sub section.main article p {
    font-size: 3.2vw;
    line-height: 2;
    margin-bottom: 15px;
  }
  body.news-sub section.main .links {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    margin-top: 33.33333333vw;
    margin-bottom: 33.33333333vw;
  }
  body.news-sub section.main .links .contact {
    margin-top: 10.66666667vw;
    text-align: center;
  }
}
body.news-sub section.related h3 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.news-sub section.related ul li a {
  display: block;
}
body.news-sub section.related ul li figure {
  mask-image: url("../images/common/edge.png");
  mask-size: cover;
  padding-top: 70%;
  position: relative;
  margin-bottom: 24px;
}
body.news-sub section.related ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
body.news-sub section.related ul li p {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
body.news-sub section.related .more {
  text-align: center;
}
body.news-sub section.related .more a {
  display: inline-block;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
body.news-sub section.related .more a:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
@media only screen and (min-width: 750px) {
  body.news-sub section.related .more a {
    font-size: 18px;
  }
  body.news-sub section.related .more a:after {
    margin-top: 3px;
  }
  body.news-sub section.related .more a:hover:after {
    animation-name: underlineAnimation;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.related .more a {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 750px) {
  body.news-sub section.related h3 {
    font-size: 30px;
    margin-bottom: 80px;
  }
  body.news-sub section.related ul {
    max-width: 948px;
    padding-left: 70px;
    padding-right: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  body.news-sub section.related ul li {
    width: 50%;
  }
  body.news-sub section.related ul li:nth-of-type(1) {
    margin-right: 40px;
  }
  body.news-sub section.related ul li figure {
    margin-bottom: 24px;
  }
  body.news-sub section.related ul li p.title {
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-bottom: 14px;
  }
  body.news-sub section.related ul li .info {
    font-size: 12px;
    letter-spacing: 0.02em;
    font-weight: bold;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  body.news-sub section.related ul li .info .partition {
    margin-left: 10px;
    margin-right: 10px;
  }
  body.news-sub section.related ul li figure {
    transition-duration: 0.4s;
    will-change: transform;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  body.news-sub section.related ul li:hover figure {
    transform: scale(1.02);
  }
  body.news-sub section.related .more {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 749px) {
  body.news-sub section.related h3 {
    font-size: 5.33333333vw;
    margin-bottom: 10.66666667vw;
  }
  body.news-sub section.related ul {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.news-sub section.related ul li:nth-of-type(1) {
    margin-bottom: 10.66666667vw;
  }
  body.news-sub section.related ul li figure {
    margin-bottom: 3.2vw;
  }
  body.news-sub section.related ul li p {
    font-size: 3.2vw;
    margin-bottom: 2.66666667vw;
  }
  body.news-sub section.related ul li .info {
    font-size: 2.4vw;
    letter-spacing: 0.02em;
    font-weight: bold;
    font-family: filicudi-solid, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.02em;
  }
  body.news-sub section.related ul li .info .partition {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  body.news-sub section.related .more {
    margin-top: 13.33333333vw;
  }
}
body.privacypolicy h2 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.privacypolicy h2 {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.privacypolicy h2 {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.privacypolicy .wrap {
  letter-spacing: 0.1em;
  line-height: 2;
}
@media only screen and (min-width: 750px) {
  body.privacypolicy h2 {
    margin-bottom: 120px;
  }
  body.privacypolicy .wrap {
    max-width: 948px;
    padding-left: 114px;
    padding-right: 114px;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
  }
  body.privacypolicy .wrap p {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 749px) {
  body.privacypolicy h2 {
    margin-bottom: 18.66666667vw;
  }
  body.privacypolicy .wrap {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
    font-size: 2.93333333vw;
  }
  body.privacypolicy .wrap p {
    margin-bottom: 4vw;
  }
}
body.terms h2 {
  text-align: center;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 750px) {
  body.terms h2 {
    font-size: 36px;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 749px) {
  body.terms h2 {
    font-size: 6.4vw;
    letter-spacing: -0.26666667vw;
  }
}
body.terms .wrap h3 {
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: center;
}
body.terms .wrap p {
  letter-spacing: 0.1em;
  line-height: 2;
}
@media only screen and (min-width: 750px) {
  body.terms h2 {
    margin-bottom: 120px;
  }
  body.terms .wrap {
    max-width: 948px;
    padding-left: 114px;
    padding-right: 114px;
    margin-left: auto;
    margin-right: auto;
  }
  body.terms .wrap h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  body.terms .wrap p {
    font-size: 13px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 749px) {
  body.terms h2 {
    margin-bottom: 18.66666667vw;
  }
  body.terms .wrap {
    padding-left: 13.33333333vw;
    padding-right: 13.33333333vw;
  }
  body.terms .wrap h3 {
    font-size: 4vw;
    margin-bottom: 6.66666667vw;
  }
  body.terms .wrap p {
    font-size: 2.93333333vw;
    margin-bottom: 4vw;
  }
}
body.maintenance header {
  padding: 20px;
  text-align: center;
}
body.maintenance header img {
  width: 150px;
}
body.maintenance main {
  padding: 50px;
  text-align: center;
}
body.maintenance main h2 {
  font-size: 18px;
}
body.maintenance main p {
  font-size: 13px;
  margin-top: 50px;
}
