@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

a,
abbr,
address,
blockquote,
body,
caption,
cite,
code,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
label,
legend,
li,
object,
ol,
p,
pre,
q,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
ul {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  font-family: @baseFont;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  min-height: 100%;
  height: 100%;
  //noinspection CssInvalidPropertyValue
  overflow: overlay;
}

body {
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  //noinspection CssInvalidPropertyValue
  overflow: overlay;
  min-height: 100%;
  height: 100%;
  // background-color: #e4e4e7;
  // background-image: url('../materials/common/noise.png');
  // background-size: 130px 130px;
  // background-position: 10px 10px;
  // animation: noise 0.8s steps(10) infinite;
  // cursor: none;
  @keyframes noise {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
}
a {
  text-decoration: none;
  color: black;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
}

dl,
dt,
dd {
  margin: 0;
}
