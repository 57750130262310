@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

h2 {
  .section-title();
}
.inner-nav {
  #inner-nav.common();
  #inner-nav.pc(40px);
  #inner-nav.sp(32);
}
.mq_pc({
  .scroll-wrapper{
    padding-top: 0;
  }
  section.head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  h2{
    margin-bottom: 120px;
  }
});

.mq_tab({});
.mq_sp({
  .scroll-wrapper{
    padding-top: .vwSp(0)[@r];
  }
  section.head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  h2{
    margin-bottom: .vwSp(140)[@r];
  }
});
