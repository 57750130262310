@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

@font-face {
  font-family: "Kinto Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@{path-fonts}KintoSans-Medium.woff2") format("woff2"),
    url("@{path-fonts}KintoSans-Medium.woff") format("woff"),
    url("@{path-fonts}KintoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Kinto Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("@{path-fonts}KintoSans-Black.woff2") format("woff2"),
    url("@{path-fonts}KintoSans-Black.woff") format("woff"),
    url("@{path-fonts}KintoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@{path-fonts}NotoSansJP-Medium.woff2") format("woff2"),
    url("@{path-fonts}NotoSansJP-Medium.woff") format("woff"),
    url("@{path-fonts}NotoSansJP-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("@{path-fonts}NotoSansJP-Black.woff2") format("woff2"),
    url("@{path-fonts}NotoSansJP-Black.woff") format("woff"),
    url("@{path-fonts}NotoSansJP-Black.ttf") format("truetype");
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("@{path-fonts}YakuHanJP-DemiLight.eot");
  src: url("@{path-fonts}YakuHanJP-DemiLight.woff2") format("woff2"),
    url("@{path-fonts}YakuHanJP-DemiLight.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01,
    U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@{path-fonts}YakuHanJP-Regular.eot");
  src: url("@{path-fonts}YakuHanJP-Regular.woff2") format("woff2"),
    url("@{path-fonts}YakuHanJP-Regular.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01,
    U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@{path-fonts}YakuHanJP-Medium.eot");
  src: url("@{path-fonts}YakuHanJP-Medium.woff2") format("woff2"),
    url("@{path-fonts}YakuHanJP-Medium.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01,
    U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("@{path-fonts}YakuHanJP-Bold.eot");
  src: url("@{path-fonts}YakuHanJP-Bold.woff2") format("woff2"),
    url("@{path-fonts}YakuHanJP-Bold.woff") format("woff");
  unicode-range: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01,
    U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
