@import (reference) "./variables";
@import (reference) "./tools";

.section-title() {
  text-align: center;
  .fontFilicudi();
  .mq_pc({
    font-size: 36px;
    letter-spacing: -2px;
  });
  .mq_sp({
    font-size: .vwSp(48)[@r];
    letter-spacing: .vwSp(-2)[@r];
  });
}

.h3() {
  text-align: center;
  letter-spacing: -0.02em;
  .fontFilicudi();
  .mq_pc({
    font-size: 32px;
  });
  .mq_sp({
    font-size: .vwSp(44)[@r];
  });
}

#rect-btn() {
  .common() {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.02em;
    border: 2px solid @color-black-pure;
    background-color: @color-white-pure;
    .fontFilicudi();
  }
  .pc(@w, @h, @fsz) {
    .mq_pc({
      width: @w;
      height: @h;
      font-size: @fsz;
      cursor: pointer;
      transition-duration: 0.2s;
      transition-property: color, background-color;
      // transition-timing-function: @ease-quart-in;
      &:hover {
        color: @color-white-pure;
        background-color: @color-black-pure;
      }
    });
  }
  .sp(@w, @h, @fsz) {
    .mq_sp({
      width: .vwSp(@w)[@r];
      height: .vwSp(@h)[@r];
      font-size: .vwSp(@fsz)[@r];
    });
  }
}

// inner-navはabout, works, newsで使いまわしたい
// SPニュースは横スクロールできるようにする..
#inner-nav() {
  .common() {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      letter-spacing: -0.02em;
      .fontFilicudi();
      li:after {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: @color-black-pure;
        transform: scaleX(0);
        transition-duration: 0.1s;
        transition-timing-function: @ease-quart-in;
      }
    }
  }
  .pc(@margin) {
    .mq_pc({
      max-width: @pc-contents-width1088;
      padding-left: 70px;
      padding-right: 70px;
      margin-left: auto;
      margin-right: auto;
      ul{
        flex-wrap: wrap;
      }
      li {
        font-size: 24px;
        cursor: pointer;
      }
      li:hover:after, li.active:after {
        transform: scaleX(1);
      }
      li:not(:first-of-type){
        margin-left: @margin;
      }
      li:not(:last-of-type){
        margin-right: @margin;
      }
    });
  }
  .sp(@margin) {
    .mq_sp({
      li {
        font-size: .vwSp(32)[@r];
        letter-spacing: -0.02em;
      }
      li.active:after{
        transform: scaleX(1);
      }
      li:not(:first-of-type){
        margin-left: .vwSp(@margin)[@r];
      }
      li:not(:last-of-type){
        margin-right: .vwSp(@margin)[@r];
      }
    });
  }
}

.edge-effect() {
  mask-image: url("@{path-img}common/edge.png");
  mask-size: cover;
}

.thumbnail-img() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}

.dark-cover() {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  //background-image: url("@{path-img}common/dot.png");
  //background-size: 4px 4px;
  z-index: 1;
}

#works-thumbnail-text() {
  .common() {
    .fontFilicudi();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .info {
      position: relative;
      span:not(:last-of-type) {
        &:after {
          content: "|";
        }
      }
    }
  }
  .pc(@fsz) {
    .mq_pc({
      padding: 10px;
    p {
      font-size: .vwPc(@fsz) [ @r];
      line-height: 1;
      letter-spacing: -0.02em;
      margin-bottom: 10px;
      @media (min-width: 1368px) {
        font-size: unit(@fsz, px);
      }
    }
    .info {
      font-size: 14px;
      span {
        &:after {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    });
  }
  .sp(@fsz) {
    .mq_sp({
      padding: .vwSp(20)[@r];
    p {
      font-size: .vwSp(@fsz) [ @r];
      line-height: 1;
      letter-spacing: -0.02em;
      margin-bottom: .vwSp(10)[@r];
    }
    .info {
      font-size: .vwSp(24) [ @r];
      span {
        &:after {
          padding-left: .vwSp(10)[@r];
          padding-right: .vwSp(10)[@r];
        }
      }
    }
    });
  }
}

#scale-thumbnail() {
  .settings() {
    transition-duration: 0.4s;
    will-change: transform;
    transition-property: transform;
    transition-timing-function: @ease-quart-out;
  }
  .motion() {
    transform: scale(1.02);
  }
}

#underline() {
  .common() {
    display: inline-block;
    .fontFilicudi();
    font-weight: bold;
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: @color-black-pure;
    }
  }
  .pc(@fsz) {
    .mq_pc({
      font-size: @fsz;
      &:after{
        margin-top: 3px;
      }
      &:hover:after{
        animation-name: underlineAnimation;
        animation-duration: 400ms;
        animation-iteration-count: 1;
        animation-timing-function: @ease-expo-out;
      }
    });
  }
  .sp(@fsz) {
    .mq_sp({
      font-size: .vwSp(@fsz)[@r];
    });
  }
}

#sns-icons() {
  .common() {
    display: flex;
    a {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .pc() {
    .mq_pc({
      a {
        height: 23px;
        margin-right: 40px;
      }
    });
  }
  .sp() {
    .mq_sp({
      justify-content: space-between;
      a {
        height: .vwSp(43) [ @r];
      }
    });
  }
}
