@keyframes underlineAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  20% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes toTopAnimation {
  0% {
    transform: translateY(34px);
  }
  100% {
    transform: translateY(-4px);
  }
}