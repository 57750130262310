@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.contact {
  input {
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
    border: @border-thick;
  }
  textarea {
    appearance: none;
    resize: none;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    border: @border-thick;
  }
  .title {
    .h3();
  }
  .form-wrapper {
    position: relative;
    overflow: hidden;
    form {
      width: 100%;
      transition-duration: 0.5s;
      transition-property: transform, height;
      p.note {
        span {
          display: none;
          margin-top: 25px;
          color: @color-red-error;
        }
        &.error {
          span {
            display: block;
          }
        }
      }
      button {
        #rect-btn.common();
        #rect-btn.pc(240px, 56px, 18px);
        #rect-btn.sp(320, 80, 30);
      }
      dl.error {
        dd {
          input,
          textarea {
            border: 1px solid @color-red-error;
          }
        }
      }
      .privacy.error {
        label:before {
          border: 1px solid @color-red-error !important;
        }
      }
    }
    .confirm {
      transform: translateX(110%);
      transition-duration: 0.5s;
      transition-property: transform, height;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      .buttons {
        display: flex;
        button {
          #rect-btn.common();
          #rect-btn.pc(240px, 56px, 18px);
          #rect-btn.sp(320, 80, 30);
        }
      }
    }
    .thanks {
      transform: translateX(110%);
      transition-duration: 0.5s;
      transition-property: transform, height;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .mq_pc({
    padding: 80px 0 0;
    .form-wrapper{
      width: 680px;
      margin: 0 auto 0;
      form{
        p.note{
          padding: 80px 0;
          text-align: center;
        }
        dl{
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          dt{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            letter-spacing: 0.1em;
            span{
              font-size: 10px;
              margin-top: 5px;
              letter-spacing: 1px;
            }
          }
          dd{
            width: 520px;
            flex-shrink: 0;
          }
          &.en{
            dt{
              letter-spacing: 0.01em;
            }
            dd{
              width: 480px;
            }
          }
        }
        dl.input{
          height: 56px;
          dt{
            justify-content: center;
          }
          input{
            display: block;
            width: 100%;
            height: 100%;
            padding: 10px;
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.7;
            background-color: @color-white-pure;
          }
        }
        dl.textarea{
          height: 240px;
          dt{
            padding-top: 10px;
          }
          textarea{
            display: block;
            width: 100%;
            height: 100%;
            padding: 10px;
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.7;
            background-color: @color-white-pure;
          }
        }
        .privacy{
          text-align: center;
          input{
            display: none;
          }
          label{
            display: inline-block;
            height: 30px;
            line-height: 30px;
            position: relative;
            font-size: 13px;
            letter-spacing: 0.1em;
            padding-left: 40px;
            cursor: pointer;
            &:before{
              content: "";
              display: block;
              width: 30px;
              height: 30px;
              background-color: @color-white-pure;
              border: 1px solid @color-black-pure;
              position: absolute;
              left: 0;
              top: 0;
            }
            &:after{
              content: "";
              display: block;
              width: 10px;
              height: 20px;
              border-right: 2px solid @color-black-pure;
              border-bottom: 2px solid @color-black-pure;
              transform: rotate(45deg);
              position: absolute;
              left: 10px;
              top: 2px;
              opacity: 0;
            }
          }
          input:checked+label:after{
            opacity: 1;
          }
        }
        button {
          margin: 80px auto 0;
        }
      }
      .confirm{
        p.note{
          padding: 80px 0;
          text-align: center;
        }
        dl{
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          dt{
            font-size: 14px;
            letter-spacing: 0.1em;
          }
          dd{
            width: 520px;
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.7;
          }
          &.en{
            dt{
              letter-spacing: 0.01em;
            }
            dd{
              width: 480px;
            }
          }
        }
        .buttons{
          justify-content: center;
          margin: 80px auto 0;
          button:first-of-type {
            margin-right: 20px;
          }
        }
      }
      .thanks{
        padding-top: 50px;
        padding-bottom: 50px;
        p{
          font-size: 14px;
          line-height: 2;
          text-align: center;
        }
      }
    }
  });
  .mq_tab({});
  .mq_sp({
    padding-top: .vwSp(110)[@r];
    .form-wrapper{
      p.note{
        font-size: .vwSp(22)[@r];
        letter-spacing: 0.1em;
        text-align: center;
        padding-top: .vwSp(127)[@r];
        margin-bottom: .vwSp(74)[@r];
      }
      form{
        padding-left: .vwSp(40)[@r];
        padding-right: .vwSp(40)[@r];
        dl{
          dt{
            font-size: .vwSp(22)[@r];
            letter-spacing: 0.1em;
            margin-bottom: .vwSp(20)[@r];
            span{
              font-size: .vwSp(20)[@r];
              letter-spacing: 0.1em;
              margin-left: .vwSp(24)[@r];
            }
          }
        }
        dl.input{
          margin-bottom: .vwSp(47)[@r];
          dt{
            justify-content: center;
          }
          input{
            display: block;
            width: 100%;
            height: .vwSp(80)[@r];
            padding: .vwSp(20)[@r];
            font-size: .vwSp(22)[@r];
            letter-spacing: 0.1em;
            line-height: 1.7;
            background-color: @color-white-pure;
          }
        }
        dl.textarea{
          margin-bottom: .vwSp(80)[@r];
          textarea{
            display: block;
            width: 100%;
            height: .vwSp(320)[@r];
            padding: .vwSp(40)[@r];
            font-size: .vwSp(22)[@r];
            letter-spacing: 0.1em;
            line-height: 1.8;
            background-color: @color-white-pure;
          }
        }
        .privacy{
          input{
            display: none;
          }
          label{
            display: inline-block;
            line-height: 1.6;
            position: relative;
            font-size: .vwSp(22)[@r];
            letter-spacing: 0.1em;
            padding-left: .vwSp(70)[@r];
            cursor: pointer;
            &:before{
              content: "";
              display: block;
              width: .vwSp(50)[@r];
              height: .vwSp(50)[@r];
              background-color: @color-white-pure;
              border: .vwSp(2)[@r] solid @color-black-pure;
              position: absolute;
              left: 0;
              top: .vwSp(8)[@r];
            }
            &:after{
              content: "";
              display: block;
              width: .vwSp(15)[@r];
              height: .vwSp(35)[@r];
              border-right: .vwSp(4)[@r] solid @color-black-pure;
              border-bottom: .vwSp(4)[@r] solid @color-black-pure;
              transform: rotate(45deg);
              position: absolute;
              left: .vwSp(18)[@r];
              top: .vwSp(12)[@r];
              opacity: 0;
            }
          }
          input:checked+label:after{
            opacity: 1;
          }
        }
        button{
          margin-top: .vwSp(88)[@r];
          margin-left: auto;
          margin-right: auto;
        }
      }
      .confirm{
        padding-left: .vwSp(40)[@r];
        padding-right: .vwSp(40)[@r];
        dl{
          margin-bottom: .vwSp(47)[@r];
          font-size: .vwSp(22)[@r];
          letter-spacing: 0.1em;
        }
        .buttons{
          button:first-of-type {
            margin-right: .vwSp(40)[@r];
          }
        }
      }
      .thanks{
        padding-left: .vwSp(40)[@r];
        padding-right: .vwSp(40)[@r];
        padding-top: .vwSp(100)[@r];
        padding-bottom: .vwSp(100)[@r];
        p{
          font-size: .vwSp(22)[@r];
          line-height: 2;
          text-align: center;
        }
      }
    }
  });
}
