@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.founder {
  .title {
    .h3();
  }
  .mq_pc({
    & {
      padding: 100px 0 0;
      margin-bottom: 400px - 100px;
    }

    ul {
      padding: 70px 0 0;
    }
    li {
      padding: 140px 0 0;
      display: flex;
      justify-content: center;

      &.-left {
        flex-direction: row-reverse;
      }
    }
    .img-wrapper {
      flex-shrink: 0;

      img {
        width: 396px;
      }
    }
    .text-wrapper {
      padding-left: 64px;
      width: auto;

      &.-left {
        padding-left: 0;
        padding-right: 64px;
      }
    }
    dl {
      width: 411px;
    }
    dl.en{
      dt{
        flex-wrap: wrap;
        .name{
          width: 100%;
        }
      }
    }
    dt {
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 3.5px;
      display: inline-block;
      padding-right: 16px;
    }
    .role1 {
      font-size: 12px;
      font-weight: 900;
      letter-spacing: -0.5px;
      display: inline-block;
      padding-top: 2px;
    }
    .slash {
      padding-left: 9px;
    }
    .role2 {
      font-size: 14px;
      .fontFilicudi();
      letter-spacing: -0.5px;
      display: inline-block;
      padding-top: 4px;
      padding-left: 9px;
    }
    dd {
      padding: 20px 0 0;
      font-size: 14px;
      line-height: 1.9;
      letter-spacing: -0.2px;
      text-align: justify;
      text-justify: inter-ideograph;
      word-wrap: break-word;
      word-break: break-all;
      overflow-wrap: break-word;
    }
  });

  .mq_tab({
    li {
      flex-direction: column !important;
      align-items: center;
    }
    .img-wrapper {
      width: 70%;

      img {
        width: 100%;
      }
    }
    .text-wrapper {
      padding-left: 0;
      padding-top: 40px;
      width: 100%;

      &.-left {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    dl {
      width: 70%;
      margin: 0 auto;
    }
  });

  .mq_sp({
    & {
      margin-bottom: .vwSp(280 - 110)[@r];
      padding: .vwSp(280-110)[@r] .vwSp(100)[@r] 0;
    }
    .title {
      font-size: .vwSp(48)[@r];
      .fontFilicudi();
      letter-spacing: -2px;
      text-align: center;
    }
    ul {
      // margin-top: .vwSp(-20)[@r];
    }
    li {
      padding: .vwSp(160)[@r] 0 0;
    }
    .img-wrapper {
      // flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    .text-wrapper {
      padding: .vwSp(80)[@r] 0 0;
    }

    dl{
      dt{
        flex-wrap: wrap;
        .name{
          width: 100%;
        }
      }
    }

    dt {
      display: flex;
      align-items: center;
    }
    span.name {
      font-size: .vwSp(32)[@r];
      font-weight: bold;
      letter-spacing: .vwSp(5)[@r];
      display: inline-block;

      &.js-textEn {
        letter-spacing: 0px;
      }

      // padding-right: .vwSp(20)[@r];
    }
    span.role1 {
      font-size: .vwSp(22)[@r];
      font-weight: 900;
      letter-spacing: -0.5px;
      display: inline-block;
      padding-top: .vwSp(7)[@r];
    }

    span.slash {
      padding-left: .vwSp(10)[@r];
      padding-right: .vwSp(10)[@r];
    }

    .role2 {
      font-size: .vwSp(22)[@r];
      .fontFilicudi();
      letter-spacing: -0.5px;
      display: inline-block;
      padding-top: .vwSp(8)[@r];
    }
    dd {
      padding: .vwSp(50)[@r] 0 0;
      font-size: .vwSp(24)[@r];
      line-height: 2.1;
      letter-spacing: .vwSp(2)[@r];
      text-align: justify;
      text-justify: inter-ideograph;
      word-wrap: break-word;
      word-break: break-all;
      overflow-wrap: break-word;
    }
  });
}
