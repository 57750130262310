@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.main {
  article {
    h3,
    p {
      letter-spacing: 0.1em;
    }
    h3 {
      font-weight: normal;
      line-height: 1.5;
    }
    p {
      line-height: 2;
    }
  }
  .team {
    background-color: @color-white-pure;
    h4 {
      letter-spacing: -0.02em;
      .fontFilicudi();
      text-align: center;
    }
  }
  .links {
    .sns {
      #sns-icons.common();
      #sns-icons.pc();
      #sns-icons.sp();
    }
    .contact {
      a {
        #underline.common();
        #underline.pc(18px);
        #underline.sp(30);
      }
    }
  }

  .mq_pc({
    &{
      width: 100%;
      max-width: @pc-contents-width1088;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 200px;
    }
    article{
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 120px;
      }
      h3, p{
        max-width: @pc-contents-width720;
        margin-left: auto;
        margin-right: auto;
      }
      h3{
        font-size: 20px;
        margin-bottom: 30px;
      }
      p{
        font-size: 14px;
        line-height: 2;
        margin-bottom: 15px;
      }
    }
    .links{
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      margin-top: 150px;
    }
  });
  .mq_tab({});
  .mq_sp({
    &{

    }
    article{
      &:not(:last-of-type){
        margin-bottom: .vwSp(300)[@r];
      }
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .vwSp(140)[@r];
      }
      h3, p{
        padding-left: .vwSp(100)[@r];
        padding-right: .vwSp(100)[@r];
      }
      h3{
        font-size: .vwSp(30)[@r];
        margin-bottom: .vwSp(50)[@r];
      }
      p{
        font-size: .vwSp(24)[@r];
        line-height: 2;
        margin-bottom: 15px;
      }
    }
    .links{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      margin-top: .vwSp(250)[@r];
      margin-bottom: .vwSp(250)[@r];
      .contact{
        margin-top: .vwSp(80)[@r];
        text-align: center;
      }
    }
  });
}
