@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

header.global-header {
  & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @color-white-pure;
    border-bottom: @border-thin;
  }

  .inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lang-selector {
    position: absolute;
    top: 0;
    right: 0;
    .fontFilicudi();

    dl {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    ul {
      display: flex;
    }

    .btn {
      display: inline-block;
      position: relative;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: black;
        transition: height 0.4s @ease-back-out;
      }
    }
  }

  .mq_pc({
    & {
      height: 65px;
      padding: 0 50px;
    }
    nav.main-nav {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      .fontFilicudi();
      font-size: 14px;

      a {
        margin-right: 40px;
        position: relative;
        @media (max-width: 850px) {
          margin-right: 20px;
        }

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          display: block;
          background-color: @color-black-pure;
          position: absolute;
          bottom: -3px;
          left: 0;
          transform: scaleX(0);
          transform-origin: center center;
          opacity: 0;
          transition-property: transform, opacity;
          transition-duration: 0.3s, 0.5s;
          transition-timing-function: @ease-expo-inOut, @ease-expo-out;
        }

        &:hover:after, &.current:after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
    .logo {
      width: 110px;
      display: block;
      height: auto;
    }
    .lang-selector {
      dt {
        font-size: 12px;
        padding-right: 32px;
        padding-top: 23px;
        padding-bottom: 0.5px;
      }

      li {
        padding-left: 20px;

        &:first-child {
          padding-left: 0;
        }
      }

      .btn {
        font-size: 12px;
        padding-top: 23px;
        cursor: pointer;

        &::before {
          left: 6px;
          width: 2px;
          height: 0px;
        }

        &.current {
          opacity: 1;

          &::before {
            height: 20px;
          }
        }
      }
    }
    .menu-trigger, .sp-menu {
      display: none;
    }
  });
  .mq_sp({
    .inner {
      height: .vwSp(100)[@r];
    }
    .logo {
      width: .vwSp(180)[@r];
    }
    .menu-trigger {
      display: block;
      height: 100%;
      width: .vwSp(140)[@r];
      //background-color: lightcoral;
      position: absolute;
      top: 0;
      right: 0;

      span {
        display: block;
        height: 2px;
        width: .vwSp(80)[@r];
        position: absolute;
        top: .vwSp(49)[@r];
        left: .vwSp(30)[@r];
        transition-timing-function: @ease-expo-inOut;
        transition-property: transform;
        transition-duration: 0.7s;
        transform-origin: center center;
        overflow: hidden;
        will-change: transform;

        &:nth-child(1) {
          transform: translateY(-5px);
        }

        &:nth-child(2) {
          transform: translateY(5px)
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          background-color: @color-black-pure;
          transition-timing-function: @ease-quart-out;
          transition-property: transform;
          transition-duration: 0.7s;
        }

        &:nth-child(2):after {
          transform: translateX(-20%);
        }
      }

      &.close {
        span:nth-child(1) {
          transform: rotate(45deg) scaleX(0.9);
        }

        span:nth-child(2) {
          transform: rotate(-45deg) scaleX(0.9);

          &:after {
            transform: none;
          }
        }
      }
    }
    nav.sp-menu {
      //display: flex;
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: .vwSp(70)[@r];
      letter-spacing: -0.03em;
      .fontFilicudi();

      ul {
        position: relative;
      }

      .main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: .vwSp(64)[@r];
        gap: .vwSp(30)[@r];
      }

      .extra{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: .vwSp(32)[@r];
        gap: .vwSp(25)[@r];
      }
      .lang{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: .vwSp(32)[@r];
        gap: .vwSp(70)[@r];
        a{
          opacity: 0.5;
          position: relative;
          &.current{
            opacity: 1;
            &:before {
              content: "";
              display: block;
              height: 2px;
              width: 100%;
              background-color: @color-black-pure;
              position: absolute;
              bottom: -4px;
              left: 0;
            }
          }
        }
      }
      .icons{
        display: flex;
        justify-content: center;
        align-items: center;
        li{
          &:not(:last-of-type){
            margin-right: .vwSp(60)[@r];
          }
        }
      }
    }
    .main-nav, .lang-selector {
      display: none;
    }
  });
}
