@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.main {
  article {
    h3,
    p {
      letter-spacing: 0.1em;
    }
    h3 {
      font-weight: normal;
      line-height: 1.5;
    }
    p {
      line-height: 2;
    }
    .video {
      padding-top: @youtube-aspect-ratio;
      position: relative;
      iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .team {
    background-color: @color-white-pure;
    border: @border-thin;
    h4 {
      letter-spacing: -0.02em;
      .fontFilicudi();
      text-align: center;
    }
    .overview {
      padding: 40px 70px 70px;
      dl {
        dt {
          .fontFilicudi();
          font-weight: bold;
          letter-spacing: -0.02em;
        }
        dd {
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .links {
    .sns {
      #sns-icons.common();
      #sns-icons.pc();
      #sns-icons.sp();
    }
    .contact {
      a {
        #underline.common();
        #underline.pc(18px);
        #underline.sp(30);
      }
    }
  }

  .mq_pc({
    &{
      width: 100%;
      max-width: @pc-contents-width1088;
      margin-left: auto;
      margin-right: auto;
    }
    article{
      &:not(:last-of-type){
        margin-bottom: 160px;
      }
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 120px;
      }
      .video{
        margin-bottom: 120px;
      }
      h3, p{
        max-width: @pc-contents-width720;
        margin-left: auto;
        margin-right: auto;
      }
      h3{
        font-size: 20px;
        margin-bottom: 30px;
      }
      p{
        font-size: 14px;
        line-height: 2;
        margin-bottom: 15px;
      }
    }
    .team{
      margin-top: 200px;
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;
      padding-top: 40px;
      margin-bottom: 80px;
      h4{
        font-size: 24px;
      }
      .overview{
        dl{
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          dt{
            width: 175px;
            padding-right: 15px;
            padding-top: 2px;
            flex-shrink: 0;
            font-size: 11px;
          }
          dd{
            font-size: 12px;
          }
        }
      }
    }
    .links{
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 200px;
    }
  });
  .mq_tab({});
  .mq_sp({
    article{
      &:not(:last-of-type){
        margin-bottom: .vwSp(300)[@r];
      }
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .vwSp(140)[@r];
      }
      .video{
        margin-bottom: .vwSp(140)[@r];
      }
      h3, p{
        padding-left: .vwSp(100)[@r];
        padding-right: .vwSp(100)[@r];
      }
      h3{
        font-size: .vwSp(30)[@r];
        margin-bottom: .vwSp(50)[@r];
      }
      p{
        font-size: .vwSp(24)[@r];
        margin-bottom: .vwSp(20)[@r];
      }
    }
    .team{
      width: .vwSp(550)[@r];
      margin-left: auto;
      margin-right: auto;
      margin-top: .vwSp(300)[@r];
      padding-top: .vwSp(40)[@r];
      margin-bottom: .vwSp(80)[@r];
      h4{
        font-size: .vwSp(32)[@r];
      }
      .overview{
        padding: .vwSp(40)[@r];
        dl{
          line-height: 1.4;
          margin-bottom: .vwSp(40)[@r];
          dt{
            font-size: .vwSp(20)[@r];
          }
          dd{
            font-size: .vwSp(22)[@r];
          }
        }
      }
    }
    .links{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      margin-bottom: .vwSp(250)[@r];
      .contact{
        margin-top: .vwSp(80)[@r];
        text-align: center;
      }
    }
  });
}
