@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.company {
  .title {
    .h3();
  }
  .mq_pc({
    & {
      padding: 100px 0 0;
      margin-bottom: 400px - 100px;
    }
    .info{
      width: 475px;
      padding: 120px 0 0;
      margin: 0 auto 120px;
      dl{
        display: flex;
        font-size: 14px;
        letter-spacing: 0.1em;
        line-height: 1.7;
        &:not(:last-of-type){
          margin-bottom: 25px;  
        }
        dt{
          width: 170px;
          flex-shrink: 0;
        }
      }
    }
    .map{
      width: 100%;
      max-width: 1368px;
      height: 300px;
      margin: 0 auto 0;
      img{
        display: block;
        width: 100%;
      }
    }
  });

  .mq_tab({});

  .mq_sp({
    & {
      padding: .vwSp(110)[@r] 0 0;
      margin-bottom: .vwSp(280 - 110)[@r];
    }
    .info{
      padding: .vwSp(0)[@r] .vwSp(100)[@r] 0;
      margin-top: .vwSp(127)[@r];
      margin-bottom: .vwSp(120)[@r];
      dl{
        font-size: .vwSp(22)[@r];
        letter-spacing: 0.1em;
        line-height: 1.4;
        &:not(:last-of-type){
          margin-bottom: .vwSp(50)[@r];
        }
        dt{
          margin-bottom: .vwSp(20)[@r];
        }
      }
    }
    .map{
      width: 100%;
      height: .vwSp(500)[@r];
      background-color: lightgray;
      img{
        display: block;
        width: 100%;
      }
    }
  });
}
