@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

section.fv {
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .canvas-wrapper {
    // opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    pointer-events: none;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .logo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    // img {
    //   transform: translateX(-1px);
    // }
  }

  .logo-inner {
    position: relative;
    overflow: hidden;
  }

  .logo-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: white;
  }

  ul.characters {
    opacity: 0;
    // transform: translateX(-1px);
  }

  .triangle {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 19px;
    transform: translateX(-1.1px);
  }
  .scroll-indicator {
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .scroll-bar {
    position: absolute;
    top: -16px;
    left: 43%;
    width: 2px;
    height: 46px;
    background-color: black;
    overflow: hidden;
    z-index: 4;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 4px;
      background-color: white;
      animation: bar 1.8s @ease-circ-inOut infinite;
    }
  }

  @keyframes bar {
    0% {
      transform: translateY(-4px);
    }
    100% {
      transform: translateY(46px);
    }
  }

  .video-wrapper {
    height: 100%;
    width: 100%;
  }

  .inner {
    position: relative;
    height: 100%;
    width: 100%;
  }

  ul.characters {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    display: grid;
    grid-template-columns: 1fr;

    li {
      width: 100%;
      pointer-events: none;
      flex-shrink: 0;
    }
  }

  .extra {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    pointer-events: none;
  }

  .title {
    position: relative;
    .fontFilicudi();
    text-align: center;
  }

  .p-fv__scroll {
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .scroll-inner {
    position: relative;
  }

  @media all and (-ms-high-contrast: none) {
    .video-wrapper {
      z-index: 1;
    }
  }

  .mq_pc({
    @media screen and (max-height: 620px) {
      ul.characters {
        display: flex !important;
      }

      li {
        display: none !important;

        &.EN {
          display: block !important;
        }
      }
    }

    .video-wrapper {
      position: relative;

      video {
        position: absolute;
        left: 50%;
        top: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: inherit;
        transform: translate(-50%, -50%);
      }
    }
    ul.characters {
      grid-template-rows: 1fr 174px 237px 176px 1fr;

      li {
        &.RU {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/ru.png");
          background-repeat: repeat;
          background-position: bottom;
        }

        &.KR {
          grid-row: 2 / 3;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/kr.png");
          background-repeat: repeat-x;
          background-position: bottom;
          background-size: 390px 125px;
        }

        &.EN {
          grid-row: 3 / 4;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/en.png");
          background-repeat: repeat-x;
          background-position: center;
          background-size: 1610px 190px;
          transform: translateX(1.5px) translateY(1px);
        }

        &.CH {
          grid-row: 4 / 5;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/ch.png");
          background-repeat: repeat-x;
          background-position: top;

        }

        &.TA {
          grid-row: 5 / 6;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/ta.png");
          background-repeat: repeat;
          background-position: top;
        }
      }
    }
    .logo {
      img {
        width: 620px;
      }
    }


    .extra {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      padding-bottom: 450px;
      font-size: 18px;
      display: flex;
      align-items: center;

      img {
        width: 224px;
      }
    }
    .scroll-indicator {
      bottom: 20%;
      left: calc(50% - 26px);
      padding-top: 450px;
    }
    .scroll-inner {
      width: 33px;
      height: 26px;
      transform: translate(12px);
    }
  });

  .mq_sp({
    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: .vwSp(2904)[@r];
        transform: translateX(-32%);
      }
    }
    .inner {
      position: fixed;
    }
    ul.characters {
      grid-template-rows: 1fr .vwSp(156)[@r] .vwSp(210)[@r] .vwSp(313)[@r] .vwSp(217)[@r] .vwSp(212)[@r] 1fr;

      li {
        &.IND01 {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          background-repeat: repeat;
          background-image: url("@{path-img}fv/ind_sp.png");
          background-size: .vwSp(648)[@r] .vwSp(216)[@r];
          background-position: bottom;
        }

        &.RU {
          grid-row: 2 / 3;
          grid-column: 1 / 2;
          background-repeat: repeat;
          background-position: bottom;
          background-image: url("@{path-img}fv/ru_sp.png");
          background-size: .vwSp(871)[@r] .vwSp(210)[@r];
        }

        &.KR {
          grid-row: 3 / 4;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/kr_sp.png");
          background-repeat: repeat-x;
          background-position: bottom;
          background-size: .vwSp(427)[@r] .vwSp(137)[@r];
        }

        &.EN {
          grid-row: 4 / 5;
          grid-column: 1 / 2;
          background-size: .vwSp(548)[@r] .vwSp(142)[@r];
          background-image: url("@{path-img}fv/en_sp.png");
          background-repeat: repeat-x;
          background-position: center;
          transform: translateX(.vwSp(4.5)[@r]) translateY(.vwSp(6)[@r]);
        }

        &.CH {
          grid-row: 5 / 6;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/ch_sp.png");
          background-repeat: repeat-x;
          background-size: .vwSp(524)[@r] .vwSp(146)[@r];

        }

        &.TA {
          grid-row: 6 / 7;
          grid-column: 1 / 2;
          background-image: url("@{path-img}fv/ta_sp.png");
          background-repeat: repeat-x;
          background-size: .vwSp(557)[@r] .vwSp(149)[@r];
          background-position: top;
        }

        &.IND {
          grid-row: 7 / 8;
          grid-column: 1 / 2;
          background-repeat: repeat;
          background-image: url("@{path-img}fv/ind_sp.png");
          background-size: .vwSp(648)[@r] .vwSp(216)[@r];
          background-position: top;
        }
      }
    }
    .logo {
      transform: translateY(.vwSp(-27)[@r]) translateX(1px);

      img {
        width: .vwSp(636)[@r];
      }
    }


    .extra {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      padding-top: .vwSp(243)[@r];
      padding-bottom: .vwSp(830)[@r];
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: .vwSp(343)[@r];
      }
    }
    .scroll-indicator {
      bottom: 20%;
      left: calc(50% - 26px);
      padding-top: 450px;
    }
    .scroll-inner {
      width: 33px;
      height: 26px;
      transform: translate(12px);
    }
  });
}
