@import (reference) "../_include/variables";
@import (reference) "../_include/tools";

.cursor-wrapper {
  * {
    user-select: none;
  }

  .mq_pc({
    & {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 9999;
    }
    .cursor {
      position: relative;
    }
    .pointer {
      width: 10px * 14;
      height: 10px * 14;
      background-color: black;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0);
      position: relative;
      left:  -65px;
      top:  -65px;
      //will-change: transform;
    }
    .arrow {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -50%);
      will-change: opacity;
      opacity: 0;
      span{
        display: block;
        transform: translateX(-35px) scaleX(0);
        will-change: transform;
      }
    }

    .drag {
      position: absolute;
      top: 0;
      left: 0;
      color: @color-white-pure;
      .fontFilicudi();
      letter-spacing: 0.2em;
      transform: translate(-37%, -85%);
      font-size: 18px;
      opacity: 0;
      will-change: opacity;

      span {
        display: block;
        will-change: transform;
        transform:translateY(20px);
      }
    }
  });

  .mq_sp({
    & {
      display: none;
    }
  });
}

*[data-cursor="coming"] {
  cursor: default !important;
}
