@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.contents {
  ul {
    li {
      a {
        display: block;
      }
      figure {
        .edge-effect();
        padding-top: @thumbnail-aspect-ratio;
        position: relative;
        margin-bottom: 25px;
        img {
          .thumbnail-img();
        }
      }
    }
  }
  .more {
    text-align: center;
    span {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }
  .mq_pc({
    margin-top: 120px;
    ul{
      max-width: @pc-contents-width1088;
      padding-left: 70px;
      padding-right: 70px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      li{
        width: calc(50% - 20px);
        &:not(:nth-of-type(-n+2)){
          margin-top: 40px;
        }
        &:nth-of-type(odd){
          margin-right: 40px;
        }
        figure{
          margin-bottom: 25px;
        }
        p.title{
          font-size: 14px;
          letter-spacing: 0.1em;
          margin-bottom: 14px;
        }
        .info{
          font-size: 12px;
          letter-spacing: 0.02em;
          font-weight: bold;
          .fontFilicudi();
          .partition{
            margin-left: 10px;
            margin-right: 10px;
          }
        }
        figure{
          #scale-thumbnail.settings();
        }
      }
      li:hover{
        figure{
          #scale-thumbnail.motion();
        }
      }
    }
    .more{
      margin-top: 80px;
    }
  });
  .mq_tab({});
  .mq_sp({
    margin-top: .vwSp(120)[@r];
    ul{
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];
      li{
        &:not(:first-of-type){
          margin-top: .vwSp(80)[@r];
        }
        figure{
          margin-bottom: .vwSp(40)[@r];
        }
        p.title{
          font-size: .vwSp(24)[@r];
          letter-spacing: 0.1em;
          line-height: 1.65;
          margin-bottom: .vwSp(30)[@r];
        }
        .info{
          font-size: .vwSp(18)[@r];
          letter-spacing: 0.02em;
          font-weight: bold;
          .fontFilicudi();
          .partition{
            margin-left: .vwSp(15)[@r];
            margin-right: .vwSp(15)[@r];
          }
        }
      }
    }
    .more{
      margin-top: .vwSp(80)[@r];
    }
  });
}
