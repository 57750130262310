@responsive-threshold1: 719px;
@responsive-threshold2: 1119px;

@color-black-pure: #000000;
@color-white-pure: #ffffff;
@color-gray: #4C4C4C;
@color-red-error: #c70000;

@path-fonts: "../fonts/";
@path-img: "../images/";
@path-misc: "../images/misc/";

@baseFont: "YakuHanJP", "Noto Sans JP", "游ゴシック体", YuGothic, "Yu Gothic M",
  "游ゴシック Medium", "Yu Gothic Medium", "メイリオ", Meiryo, sans-serif;

.fontFilicudi() {
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.02em;
}

@thumbnail-aspect-ratio: 70%;
@youtube-aspect-ratio: 56.25%;

@breakPoint-sp: 749px;
@breakPoint-pc: 750px;
@breakPoint-tab: 950px;

@designSize-sp: 750;
@designSize-pc: 1368;
@maxPcWidth: 100%;
@maxTabletWidth: 950px;

@pc-contents-width1088: 1088px;
@pc-contents-width720: 720px;
@pc-contents-width808: 808px;
@pc-contents-width948: 948px;

@border-thick: 1px solid @color-black-pure;
@border-thin: 1px solid #e6e6e6;

// ==========================================================================
//
//   easing
//
// ==========================================================================

@ease-linear: linear;
// sin -
@ease-sin-in: cubic-bezier(0.12, 0, 0.39, 0);
@ease-sin-out: cubic-bezier(0.61, 1, 0.88, 1);
@ease-sin-inOut: cubic-bezier(0.37, 0, 0.63, 1);

// quad -
@ease-quad-in: cubic-bezier(0.11, 0, 0.5, 0);
@ease-quad-out: cubic-bezier(0.5, 1, 0.89, 1);
@ease-quad-inOut: cubic-bezier(0.45, 0, 0.55, 1);

// cubic -
@ease-cubic-in: cubic-bezier(0.32, 0, 0.67, 0);
@ease-cubic-out: cubic-bezier(0.33, 1, 0.68, 1);
@ease-cubic-inOut: cubic-bezier(0.65, 0, 0.35, 1);

// quart -
@ease-quart-in: cubic-bezier(0.5, 0, 0.75, 0);
@ease-quart-out: cubic-bezier(0.25, 1, 0.5, 1);
@ease-quart-inOut: cubic-bezier(0.76, 0, 0.24, 1);

// quint -
@ease-quint-in: cubic-bezier(0.64, 0, 0.78, 0);
@ease-quint-out: cubic-bezier(0.22, 1, 0.36, 1);
@ease-quint-inOut: cubic-bezier(0.83, 0, 0.17, 1);

// expo -
@ease-expo-in: cubic-bezier(0.7, 0, 0.84, 0);
@ease-expo-out: cubic-bezier(0.16, 1, 0.3, 1);
@ease-expo-inOut: cubic-bezier(0.87, 0, 0.13, 1);

// circ -
@ease-circ-in: cubic-bezier(0.55, 0, 1, 0.45);
@ease-circ-out: cubic-bezier(0, 0.55, 0.45, 1);
@ease-circ-inOut: cubic-bezier(0.85, 0, 0.15, 1);

// back -
@ease-back-in: cubic-bezier(0.36, 0, 0.66, -0.56);
@ease-back-out: cubic-bezier(0.34, 1.56, 0.64, 1);
@ease-back-inOut: cubic-bezier(0.68, -0.6, 0.32, 1.6);
