@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

section.intro {
  header {
    .fontFilicudi();
    letter-spacing: -0.02em;

    h2 {
      text-align: center;
    }

    ul {
      text-align: center;
      li {
        display: inline;
        &:after {
          content: "|";
        }

        &:last-of-type:after {
          display: none;
        }
      }
    }
  }

  .description {
    p {
      line-height: 2;
      letter-spacing: 0.1em;
    }
  }

  .link {
    a {
      #underline.common();
      #underline.pc(18px);
      #underline.sp(30);
    }
  }

  .mq_pc({
    & {
      width: 100%;
      max-width: @pc-contents-width1088;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 120px;
    }
    header {
      margin-bottom: 120px;

      h2 {
        font-size: 34px;
        margin-bottom: 20px;
      }

      ul {
        width: @pc-contents-width720;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;

        li {
          &:after {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
    .kv {
      margin-bottom: 120px;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .description {
      margin-bottom: 80px;
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;

      p {
        font-size: 14px;
      }
    }
    .link {
      max-width: @pc-contents-width720;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
    }
  });

  .mq_tab({
  });
  .mq_sp({
    & {
      margin-bottom: .vwSp(140)[@r];
    }
    header {
      margin-bottom: .vwSp(140)[@r];

      h2 {
        font-size: .vwSp(44)[@r];
        line-height: 1.08;
        margin-bottom: .vwSp(24)[@r];
      }

      ul {
        font-size: .vwSp(22)[@r];
        padding-left: .vwSp(100)[@r];
        padding-right: .vwSp(100)[@r];
        li {
          &:after {
            padding-left: .vwSp(20)[@r];
            padding-right: .vwSp(20)[@r];
          }
        }
      }
    }

    .kv {
      margin-bottom: .vwSp(140)[@r];
    }
    .description {
      margin-bottom: .vwSp(200)[@r];
      padding-left: .vwSp(100)[@r];
      padding-right: .vwSp(100)[@r];

      p {
        font-size: .vwSp(24)[@r];
      }
    }

    .link {
      text-align: center;

      a {
        display: inline-block;
        font-size: 18px;
        letter-spacing: -0.02em;
        .fontFilicudi();
        font-weight: bold;
        cursor: pointer;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: @color-black-pure;
        }
      }
    }
  });
}
