@import (reference) "../_include/variables";
@import (reference) "../_include/tools";
@import (reference) "../_include/decorations";

h2 {
  .section-title();
}
.wrap {
  h3 {
    font-weight: normal;
    letter-spacing: 0.1em;
    text-align: center;
  }
  p {
    letter-spacing: 0.1em;
    line-height: 2;
  }
}

.mq_pc({
  h2{
    margin-bottom: 120px;
  }
  .wrap{
    max-width: @pc-contents-width948;
    padding-left: 114px;
    padding-right: 114px;
    margin-left: auto;
    margin-right: auto;
    h3{
      font-size: 20px;
      margin-bottom: 30px;
    }
    p{
      font-size: 13px;
      margin-bottom: 30px;
    }
  }
});
.mq_sp({
  h2{
    margin-bottom: .vwSp(140)[@r];
  }
  .wrap{
    padding-left: .vwSp(100)[@r];
    padding-right: .vwSp(100)[@r];
    h3{
      font-size: .vwSp(30)[@r];
      margin-bottom: .vwSp(50)[@r];
    }
    p{
      font-size: .vwSp(22)[@r];
      margin-bottom: .vwSp(30)[@r]; 
    }
  }
});
